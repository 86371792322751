export default function orderSensor(data, pageType = "mdd") {
  const priorities = {
    high: "#FF7B49",
    medium: "#FFC82C",
    low: "#DBDBDB",
  };
  let formatedNotifications = [];
  const highPrioritiesHigh = [];
  const highPrioritiesMedium = [];
  const highPrioritiesLow = [];
  const highPrioritiesRooms = [];
  const mediumPrioritiesRooms = [];
  const lowPrioritiesRooms = [];
  const linkBeacons = [];
  const nonPrioritiesRooms = [];

  function parseDifferent(different) {
    let totalMinutes = 0;
    if (!different) return totalMinutes;
    let hoursMatch = different.match(/(\d+)h/);
    if (hoursMatch) {
      totalMinutes += parseInt(hoursMatch[1]) * 60;
    }
    let minutesMatch = different.match(/(\d+)m/);
    if (minutesMatch) {
      totalMinutes += parseInt(minutesMatch[1]);
    }
    return totalMinutes;
  }

  data.map((i) => {
    if (pageType == "mdd") {
      if (i.subrooms && i.subrooms.length > 0) {
        i.subrooms.map((j) => {
          j.highRoomName = i.room_name;
          if (j.notification) {
            if (!j.notification.link_beacon) {
              if (j.notification.unlink_beacon) {
                linkBeacons.push(j);
              } else if (j.notification.color === priorities.high) {
                if (
                  !j.notification.beacon.position_corrected &&
                  j.notification.beacon.signal_beacon &&
                  j.notification.tag != "suspend" &&
                  !j.notification.suspendFlag
                ) {
                  highPrioritiesHigh.push(j);
                } else if (!j.notification.beacon.signal_beacon) {
                  highPrioritiesLow.push(j);
                } else {
                  highPrioritiesMedium.push(j);
                }
              } else if (j.notification.color === priorities.medium) {
                mediumPrioritiesRooms.push(j);
              } else if (j.notification.color === priorities.low) {
                lowPrioritiesRooms.push(j);
              }
            } else {
              linkBeacons.push(j);
            }
          } else {
            nonPrioritiesRooms.push(j);
          }
        });
      } else {
        if (i.notification) {
          if (!i.notification.link_beacon) {
            if (i.notification.unlink_beacon) {
              linkBeacons.push(i);
            } else if (i.notification.color === priorities.high) {
              if (
                !i.notification.beacon.position_corrected &&
                i.notification.beacon.signal_beacon &&
                i.notification.tag != "suspend" &&
                !i.notification.suspendFlag
              ) {
                highPrioritiesHigh.push(i);
              } else if (!i.notification.beacon.signal_beacon) {
                highPrioritiesLow.push(i);
              } else {
                highPrioritiesMedium.push(i);
              }
            } else if (i.notification.color === priorities.medium) {
              mediumPrioritiesRooms.push(i);
            } else if (i.notification.color === priorities.low) {
              lowPrioritiesRooms.push(i);
            }
          } else {
            linkBeacons.push(i);
          }
        } else {
          nonPrioritiesRooms.push(i);
        }
      }
    } else if (pageType == "home") {
      if (i.color) {
        if (!i.link_beacon) {
          if (i.unlink_beacon) {
            linkBeacons.push(i);
          } else if (i.color === priorities.high) {
            if (
              !i.beacon.position_corrected &&
              i.beacon.signal_beacon &&
              i.tag != "suspend" &&
              !i.suspendFlag
            ) {
              highPrioritiesHigh.push(i);
            } else if (!i.beacon.signal_beacon) {
              highPrioritiesLow.push(i);
            } else {
              highPrioritiesMedium.push(i);
            }
          } else if (i.color === priorities.medium) {
            mediumPrioritiesRooms.push(i);
          } else if (i.color === priorities.low) {
            lowPrioritiesRooms.push(i);
          }
        } else {
          linkBeacons.push(i);
        }
      }
    }
  });

  const itemsToSort = [
    ...highPrioritiesHigh,
    ...highPrioritiesMedium,
    ...mediumPrioritiesRooms,
    ...lowPrioritiesRooms,
  ];

  itemsToSort.forEach((item) => {
    let differentValue =
      item.different || (item.notification && item.notification.different);
    item.totalMinutes = parseDifferent(differentValue);
  });

  const highPriorityItems = itemsToSort.filter(
    (item) => item.notification && item.notification.color === "#FF7B49"
  );
  const otherItems = itemsToSort.filter(
    (item) => !item.notification || item.notification.color !== "#FF7B49"
  );

  highPriorityItems.sort((a, b) => b.totalMinutes - a.totalMinutes);
  otherItems.sort((a, b) => b.totalMinutes - a.totalMinutes);

  const sortedItems = [...highPriorityItems, ...otherItems];

  formatedNotifications.push(
    ...highPrioritiesLow,
    ...sortedItems,
    ...linkBeacons,
    ...nonPrioritiesRooms
  );

  return formatedNotifications;
}
