import React, { useEffect, useState } from "react";
import {
  Col,
  Container,
  Row,
  Modal,
  Button,
  Form,
  Spinner,
  Table,
} from "react-bootstrap";
import { useSelector, useDispatch } from "react-redux";

import Icon from "../../functions/Icon";
import Colors from "../../styles/Colors";
import "./styles.css";
import api from "../../services/api";
import { Show_Alert } from "../../store/actions/alert";
import { GetColorAvaliation } from "../../functions/utils";
import moment from "moment";

var date_start = moment().clone().startOf("month").format("YYYY-MM-DD");
var date_end = moment().format("YYYY-MM-DD");

function ListPatientsOverview({ ...props }) {
  const dispatch = useDispatch();
  const { handler_show_modal, show_modal, typeClicked, history } = props;
  const unit = useSelector((state) => state.unit);

  const [patients, setPatients] = useState([]);
  const [orders, setOrders] = useState("score");
  const [orders_type, setOrders_type] = useState("ASC");
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    function LoadPatients() {
      var score = "";
      var has_wound = "";
      var type = "";
      var dashboard = "";

      switch (typeClicked) {
        case "patients_risks":
          score = "none,low,moderate,high,veryhigh";
          break;
        case "patients_risks_wounds_incidence":
          score = "low,moderate,high,veryhigh";
          type = "incidence";
          dashboard = "risk";
          break;
        case "patients_wounds":
          has_wound = 1;
          dashboard = "wound";
          break;
        case "wounds_admission":
          type = "admission";
          dashboard = "wound";
          break;
        case "wounds_incidence":
          type = "incidence";
          dashboard = "wound";
          break;
      }

      api
        .get(`/patients?page=${1}&pageSize=${9999999}`, {
          params: {
            unit_id: unit.selecionado.id,
            score,
            orders,
            orders_type,
            has_wound,
            type,
            date_start,
            date_end,
            dashboard,
          },
        })
        .then((response) => {
          if (response.status == 200) {
            console.log("LoadPatients");
            console.log(response.data);
            setPatients(response.data.data);
          }
        })
        .catch((error) => {
          console.log("LoadPatients error " + error);
        })
        .finally(() => setLoading(false));
    }

    LoadPatients();
  }, [orders, orders_type, typeClicked]);

  const handleMouseDown = (e) => {
    const patientId = e.currentTarget.getAttribute("data-patient-id");
    const patientName = e.currentTarget.getAttribute("data-patient-name");

    dispatch({
      type: "SET_PATIENT_SELECTED",
      dados: { id: patientId, name: patientName },
    });
    dispatch({ type: "RESET_PATIENT_TAB" });
    if (e.button === 0) {
      history.push({
        pathname: "/Pacientes/Perfil-Paciente",
      });
    } else if (e.button === 1) {
      window.open("/Pacientes/Perfil-Paciente", "_blank");
    }
  };

  return (
    <Modal
      show={show_modal}
      onHide={() => handler_show_modal(false)}
      size="xl"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Body className="p-0">
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            padding: 20,
            paddingBottom: 6,
          }}
        >
          <div>
            <div className="modal-title">Pacientes</div>
            {/* <p className="subtitle-report">subtitle</p> */}
          </div>
          <div>
            <div
              className="modal-close-btn"
              onClick={() => handler_show_modal(false)}
            >
              <Icon color={Colors.darkest_gray} size={15} icon="x" />
            </div>
          </div>
        </div>

        {loading ? (
          <div className="my-3 d-flex justify-content-center">
            <Spinner animation="border" variant="primary" />
          </div>
        ) : (
          <div className="modal-all">
            <div>
              <Table>
                <thead>
                  <tr>
                    <th style={{ border: "none" }}>N°</th>
                    <th
                      onClick={() => {
                        setOrders("name");
                        setOrders_type("ASC");
                      }}
                      style={{ border: "none", cursor: "pointer" }}
                    >
                      Nome
                    </th>
                    <th
                      onClick={() => {
                        setOrders("score");
                        setOrders_type("ASC");
                      }}
                      style={{ border: "none", cursor: "pointer" }}
                    >
                      Avaliação de risco
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {patients.map((item, index) => {
                    return (
                      <tr key={item.id}>
                        <td>{index + 1}</td>
                        <td
                          onMouseDown={handleMouseDown}
                          data-patient-id={item.id}
                          style={{ cursor: "pointer" }}
                          data-patient-name={item.name}
                        >
                          <a
                            href={`/Pacientes/Perfil-Paciente?id=${item.id}`}
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            {item.name}
                          </a>
                        </td>
                        <td>
                          <div
                            className="d-flex align-items-center"
                            style={{ width: 240 }}
                          >
                            <div
                              style={{
                                width: 18,
                                height: 18,
                                borderRadius: 14,
                                backgroundColor: GetColorAvaliation(item.score),
                                marginLeft: 1,
                                marginRight: 11,
                              }}
                            />
                            {item.score > 0 && (
                              <p style={{ marginRight: 2 }}>
                                Escore {item.score}:
                              </p>
                            )}
                            <p>{item.score_description}</p>
                          </div>
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
              </Table>
            </div>
          </div>
        )}
      </Modal.Body>
    </Modal>
  );
}

export default ListPatientsOverview;
