import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import {
  Col,
  Container,
  Row,
  Modal,
  Button,
  Form,
  Spinner,
  Dropdown,
  Overlay,
  OverlayTrigger,
  Tooltip,
} from "react-bootstrap";
import InputMask from "react-input-mask";
import Select from "react-select";

import Icon from "../../../../functions/Icon";
import api from "../../../../services/api";
import { Show_Alert } from "../../../../store/actions/alert";
import Colors from "../../../../styles/Colors";
import moment from "moment";
import {
  GenerateId,
  convertTimeToHours,
  convertHoursToTime,
} from "../../../../functions/utils";

import SwitchSelector from "./SwitchSelector";

var removeSchedule = [];

function ProtocoloDecubito() {
  const dispatch = useDispatch();
  const user = useSelector((state) => state.user);
  const unit = useSelector((state) => state.unit);
  const patientSelected = useSelector((state) => state.patientSelected);

  const [loadingBtn, setLoadingBtn] = useState("none");
  const [loading, setLoading] = useState(true);
  const [showPw, setShowPw] = useState(false);

  const [listPosition, setListPosition] = useState([]);

  const [schedules, setSchedules] = useState([]);

  const [decubitProtocolType, setDecubitProtocolType] = useState(0);
  const [progressiveDefaultTime, setProgressiveDefaultTime] = useState(0);
  const [penaltyTime, setPenaltyTime] = useState(0);

  const [verticalAngulation, setVerticalAngulation] = useState(51);
  const [horizontalAngulation, setHorizontalAngulation] = useState(10);
  const [progressiveTimeUnit, setProgressiveTimeUnit] = useState("hours");

  useEffect(() => {
    function LoadListPosition() {
      api
        .get(`/hospitals/${user.dados.hospital_id}/positions`, {
          params: {
            permission_id: "1,2,3",
            unit_id: patientSelected.dados.unit_id,
          },
        })
        .then((response) => {
          if (response.status == 200) {
            console.log("LoadListPosition");
            console.log(response.data);

            var array = [];
            response.data.map((item) => {
              array.push({ label: item.name, value: item.id });
            });
            setListPosition(array);
          }
        })
        .catch((error) => {
          console.log(error);
        });
    }

    LoadListPosition();
  }, []);

  function LoadAngulationUnit() {
    api
      .get(`/units/${unit.selecionado?.id}/angulation`)
      .then((response) => {
        if (response.status == 200) {
          const angulation = response.data;
          setVerticalAngulation(angulation.vertical_angulation);
          setHorizontalAngulation(angulation.horizontal_angulation);
        }
      })
      .finally(() => setLoading(false));
  }

  useEffect(() => {
    function LoadSchedulesHospital() {
      api
        .get(`/hospitals/${user.dados.hospital_id}/schedules`)
        .then((response) => {
          if (response.status == 200) {
            console.log("LoadSchedulesHospital");
            console.log(response.data);
            var array = [];
            response.data.map((item, index) => {
              array.push({
                register: true,
                id: item.id,
                schedule: moment(item.schedule, "HH:mm:ss").format("HH:mm"),
                position_id: item.position_id,
              });
            });

            setSchedules(array);
          }
        })
        .catch((error) => {
          console.log(error);
          dispatch(
            Show_Alert({
              type: "error",
              msg: "Não foi possível carregar a lista de protocolo de decúbito do hospital",
            })
          );
        })
        .finally(() => setLoading(false));
    }

    function LoadProgressiveTimeHospital() {
      api
        .get(`/default-settings/hospitals/progressive-time`)
        .then((response) => {
          if (response.status == 200) {
            const progressiveTimeData = response.data;
            setProgressiveTimeUnit(progressiveTimeData.time_unit);
            setProgressiveDefaultTime(
              progressiveTimeData.time_unit == "minutes"
                ? convertTimeToHours(progressiveTimeData.hours) * 60
                : convertTimeToHours(progressiveTimeData.hours)
            );
            setPenaltyTime(progressiveTimeData.penalty_time);
            if (progressiveTimeData.is_progressive) {
              setDecubitProtocolType(1);
            }
          }
        })
        .finally(() => setLoading(false));
    }

    LoadSchedulesHospital();
    LoadProgressiveTimeHospital();
  }, []);

  useEffect(() => {
    LoadAngulationUnit();
  }, [unit]);

  function VerifyFields() {
    var ret = false;

    var fields = true;
    schedules.map((item) => {
      //Troca cor do input horario pra vermelho
      if (item.schedule == "" || item.schedule.length < 5) {
        item.errorTime = true;
      } else {
        item.errorTime = false;
      }

      //Troca cor do select posição pra vermelho
      if (item.position_id == 0) {
        item.errorPosition = true;
      } else {
        item.errorPosition = false;
      }

      if (
        item.schedule == "" ||
        item.position_id == 0 ||
        item.schedule.length < 5
      ) {
        if (fields) fields = false;
      }
    });

    if (fields) ret = true;
    return ret;
  }

  function convertTimeToHours(time) {
    var parts = time.split(":");
    var hours = parseInt(parts[0], 10);
    var minutes = parseInt(parts[1], 10);
    var seconds = parseInt(parts[2], 10);

    var totalHours = hours + minutes / 60 + seconds / 3600;
    return totalHours;
  }

  function convertHoursToTime(hours) {
    var totalSeconds = Math.round(hours * 3600); // Convert hours to seconds
    var hh = Math.floor(totalSeconds / 3600); // Calculate hours
    var mm = Math.floor((totalSeconds % 3600) / 60); // Calculate minutes
    var ss = totalSeconds % 60; // Calculate remaining seconds

    return [hh, mm, ss].map((v) => v.toString().padStart(2, "0")).join(":");
  }

  function HandlerTime(value, id) {
    var found = schedules.find((item) => item.id == id);
    if (found != undefined) {
      var newList = schedules.map((item) => {
        if (item.id == found.id) {
          item.schedule = value;
          item.edit = true;
        }
        return item;
      });
      setSchedules(newList);
    }
  }

  function HandlerPosition(obj, id) {
    var found = schedules.find((item) => item.id == id);
    if (found != undefined) {
      var newList = schedules.map((item) => {
        if (item.id == found.id) {
          item.position_id = obj.value;
          item.edit = true;
        }
        return item;
      });
      setSchedules(newList);
    }
  }

  function RemoveSchedule() {
    removeSchedule.map((item) => {
      api
        .delete(`/schedules/${item.id}`)
        .then((response) => {
          if (response.status == 204) {
            console.log("RemoveSchedule success");
            console.log(response.data);
          }
        })
        .catch((error) => {
          console.log("RemoveSchedule error " + error);
        });
    });
  }

  function SaveSchedules() {
    RemoveSchedule();
    var arrayShedules = [];
    schedules.map((item) => {
      if (item.register == true && item.edit != true) return false;
      arrayShedules.push({
        id: item.register == true ? item.id : "",
        position_id: item.position_id,
        schedule: item.schedule,
      });
    });

    setLoadingBtn("flex");
    let progressiveTimeInHours = progressiveDefaultTime;
    if (progressiveTimeUnit === "minutes") {
      progressiveTimeInHours = (
        parseFloat(progressiveDefaultTime) / 60
      ).toFixed(5);
    }
    console.log({
      is_progressive: true,
      hours: convertHoursToTime(progressiveTimeInHours),
      penalty_time: penaltyTime,
      time_unit: progressiveTimeUnit,
    });
    if (decubitProtocolType === 1) {
      api.post(`/default-settings/hospitals/progressive-time`, {
        is_progressive: true,
        hours: convertHoursToTime(progressiveTimeInHours),
        penalty_time: penaltyTime,
        time_unit: progressiveTimeUnit,
      });
    } else {
      api.post(`/default-settings/hospitals/progressive-time`, {
        is_progressive: false,
      });
    }
    api.post(`/units/${unit.selecionado?.id}/angulation`, {
      vertical_angulation:
        verticalAngulation > 0 && !isNaN(verticalAngulation)
          ? parseFloat(verticalAngulation)
          : 1,
      horizontal_angulation:
        horizontalAngulation > 0 && !isNaN(horizontalAngulation)
          ? parseFloat(horizontalAngulation)
          : 1,
    });
    if (horizontalAngulation == "") {
      setHorizontalAngulation(1);
    }
    if (verticalAngulation == "") {
      setVerticalAngulation(1);
    }
    api
      .post(`/hospitals/${user.dados.hospital_id}/schedules`, {
        schedules: arrayShedules,
      })
      .then((response) => {
        if (response.status == 200) {
          console.log("SaveSchedules success");
          console.log(response.data);

          dispatch(
            Show_Alert({ type: "success", msg: "Protocolo de decúbito salvo" })
          );
        }
      })
      .catch((error) => {
        console.log("SaveSchedules error " + error);
        dispatch(
          Show_Alert({
            type: "error",
            msg: "Não foi possível salvar o protocolo de decúbito do hospital.",
          })
        );
      })
      .finally(() => setLoadingBtn("none"));
  }

  function SaveProgressiveTime() {
    setLoadingBtn("flex");
    api
      .post(`/default-settings/hospitals/progressive-time`, {
        is_progressive: true,
        hours: convertHoursToTime(progressiveDefaultTime),
      })
      .then((response) => {
        if (response.status == 200) {
          console.log("SaveSchedules success");
          console.log(response.data);
          dispatch(
            Show_Alert({ type: "success", msg: "Tempo progressivo salvo" })
          );
        }
      })
      .catch((error) => {
        dispatch(
          Show_Alert({
            type: "error",
            msg: "Não foi possível salvar o tempo progressivo do hospital.",
          })
        );
      })
      .finally(() => setLoadingBtn("none"));
  }

  return (
    <div>
      <div className="mb-4">
        <h2 className="screen-name mb-4">Protocolo de decúbito</h2>
      </div>

      <div className="mb-4">
        <h3 className="sub-screen-name mb-4">Configurar angulações</h3>
        <Row className="d-flex justify-content-around">
          <div>
            <img
              className="angle-image"
              src={require("../../../../assets/img/man-angles-lateral.png")}
            ></img>
          </div>
          <div>
            <img
              className="angle-image"
              src={require("../../../../assets/img/man-angles-horizontal.png")}
            ></img>
          </div>
        </Row>
        <Row
          className="input-timers"
          style={{
            marginBottom: "2rem",
          }}
        >
          <Col
            lg={6}
            md={12}
            sm={12}
            className="d-flex align-items-end decubit-form-configuration"
          >
            <div
              style={{
                width: "100%",
              }}
            >
              <Form.Label className="field-label">Angulação Lateral</Form.Label>
              <input
                type="number"
                style={{
                  borderTopRightRadius: 0,
                  borderBottomRightRadius: 0,
                  backgroundColor: "transparent",
                }}
                className="default-input"
                placeholder="Digite o ângulo"
                value={verticalAngulation}
                onChange={(v) => {
                  let value = v.target.value;
                  if (value !== "" && parseFloat(value) < 0) {
                    setVerticalAngulation(1);
                  }
                  if (value === "" || parseFloat(value) > 0) {
                    setVerticalAngulation(value);
                  }
                }}
              />
            </div>

            <div style={{ maxWidth: "100px", width: "33%" }}>
              <Dropdown className="mdd-dropdown-time">
                <Dropdown.Toggle
                  style={{ maxHeiht: 37, fontSize: "15px" }}
                  className="w-100 dropdown-dashboard-time"
                  id="dropdown-mdd-time"
                >
                  graus (º)
                </Dropdown.Toggle>

                <Dropdown.Menu
                  rootCloseEvent={"click"}
                  className="div-drop-sort"
                >
                  {["hours"].map((item) => {
                    return (
                      <Dropdown.Item
                        style={{
                          color: Colors.brand_blue,
                        }}
                        onClick={() => {}}
                      >
                        graus (º)
                      </Dropdown.Item>
                    );
                  })}
                </Dropdown.Menu>
              </Dropdown>
            </div>
          </Col>
          <Col
            lg={6}
            md={12}
            sm={12}
            className="d-flex align-items-end decubit-form-configuration"
          >
            <div
              style={{
                width: "100%",
              }}
            >
              <Form.Label className="field-label">
                Angulação Vertical
              </Form.Label>
              <input
                type="number"
                style={{
                  borderTopRightRadius: 0,
                  borderBottomRightRadius: 0,
                  backgroundColor: "transparent",
                }}
                className="default-input"
                placeholder="Digite o período"
                value={horizontalAngulation}
                onChange={(v) => {
                  let value = v.target.value;
                  if (value !== "" && parseFloat(value) < 0) {
                    setHorizontalAngulation(1);
                  }
                  if (value === "" || parseFloat(value) > 0) {
                    setHorizontalAngulation(value);
                  }
                }}
              />
            </div>

            <div style={{ maxWidth: "100px", width: "33%" }}>
              <Dropdown className="mdd-dropdown-time">
                <Dropdown.Toggle
                  style={{ maxHeiht: 37, fontSize: "15px" }}
                  className="w-100 dropdown-dashboard-time"
                  id="dropdown-mdd-time"
                >
                  graus (º)
                </Dropdown.Toggle>

                <Dropdown.Menu
                  rootCloseEvent={"click"}
                  className="div-drop-sort"
                >
                  {["minutes"].map((item) => {
                    return (
                      <Dropdown.Item
                        style={{
                          color: Colors.brand_blue,
                        }}
                        onClick={() => {}}
                      >
                        graus (º)
                      </Dropdown.Item>
                    );
                  })}
                </Dropdown.Menu>
              </Dropdown>
            </div>
          </Col>
        </Row>
      </div>

      {loading && (
        <div className="mt-3 d-flex justify-content-center">
          <Spinner animation="border" variant="primary" />
        </div>
      )}
      {false && (
        <>
          <h3 className="sub-screen-name mb-4">Configurar posições</h3>
          <div
            className="decubit-descriptions"
            style={{
              border: "0px",
              padding: "0px",
            }}
          >
            {false && (
              <>
                <SwitchSelector
                  selected_decubit={decubitProtocolType}
                  handler_select_decubit={setDecubitProtocolType}
                />

                <p>
                  {decubitProtocolType == 0
                    ? "Tempo regressivo"
                    : "Tempo progressivo"}
                </p>
              </>
            )}
            <span>
              {decubitProtocolType == 0
                ? `No protocolo com tempo regressivo, você define os horários para
          mudança de decúbito e a posição correspondente a cada horário. O aviso
          de mudança, é realizado sempre no horário determinado.`
                : `No protocolo com tempo progressivo, 
            você define o tempo máximo que o paciente deve ficar na mesma posição e a sequência de posições. 
            Quando o paciente é reposicionado, a contagem do tempo recomeça progressivamente. 
            O aviso para mudança de decúbito só será realizado, quando o paciente atingir o tempo máximo em determinada posição.`}
            </span>
          </div>
        </>
      )}

      {!loading && (
        <div>
          {decubitProtocolType == 1 && (
            <Row
              className="input-timers"
              style={{
                marginBottom: "2rem",
              }}
            >
              <Col
                lg={6}
                md={12}
                sm={12}
                className="d-flex align-items-end decubit-form-configuration"
              >
                <div
                  style={{
                    width: "100%",
                  }}
                >
                  <Form.Label className="field-label">
                    Tempo máximo na posição
                  </Form.Label>
                  <input
                    type="number"
                    style={{
                      borderTopRightRadius: 0,
                      borderBottomRightRadius: 0,
                      backgroundColor: "transparent",
                    }}
                    className="default-input"
                    placeholder="Digite o período"
                    value={progressiveDefaultTime}
                    onChange={(v) => {
                      let val =
                        parseInt(v.target.value.replace(/^0+/, "")) || 1;
                      if (progressiveTimeUnit == "hours") {
                        val = Math.min(Math.max(val, 1), 23);
                      } else {
                        val = Math.min(Math.max(val, 1), 1440);
                      }
                      setProgressiveDefaultTime(val);
                    }}
                  />
                </div>

                <div style={{ maxWidth: "100px", width: "33%" }}>
                  <Dropdown className="mdd-dropdown-time">
                    <Dropdown.Toggle
                      style={{ fontSize: "15px" }}
                      className="w-100 dropdown-dashboard-time"
                      id="dropdown-mdd-time"
                    >
                      {progressiveTimeUnit === "hours" ? "horas" : "minutos"}
                    </Dropdown.Toggle>

                    <Dropdown.Menu
                      rootCloseEvent={"click"}
                      className="div-drop-sort"
                    >
                      <Dropdown.Item
                        style={{ color: Colors.brand_blue }}
                        onClick={() => setProgressiveTimeUnit("hours")}
                      >
                        horas
                      </Dropdown.Item>
                      <Dropdown.Item
                        style={{ color: Colors.brand_blue }}
                        onClick={() => setProgressiveTimeUnit("minutes")}
                      >
                        minutos
                      </Dropdown.Item>
                    </Dropdown.Menu>
                  </Dropdown>
                </div>
              </Col>
              <Col
                lg={6}
                md={12}
                sm={12}
                className="d-flex align-items-end decubit-form-configuration"
              >
                <div
                  style={{
                    width: "100%",
                  }}
                >
                  <Form.Label className="field-label">
                    Tempo de tolerância{" "}
                    <OverlayTrigger
                      overlay={
                        <Tooltip id="tooltip-top">
                          Tempo mínimo que o paciente precisa ficar na posição,
                          para considerar como uma mudança de decúbito
                          realizada.
                        </Tooltip>
                      }
                      placement="top"
                    >
                      <span
                        style={{
                          transform: "translate(8px, -2px)",
                          position: "absolute",
                        }}
                      >
                        <Icon icon="info" size={14} color={"#454545"}></Icon>
                      </span>
                    </OverlayTrigger>
                  </Form.Label>
                  <input
                    type="number"
                    style={{
                      borderTopRightRadius: 0,
                      borderBottomRightRadius: 0,
                      backgroundColor: "transparent",
                    }}
                    className="default-input"
                    placeholder="Digite o período"
                    value={penaltyTime}
                    onChange={(v) => {
                      let val =
                        parseInt(v.target.value.replace(/^0+/, "")) || 1;
                      val = Math.min(Math.max(val, 1), 999);
                      setPenaltyTime(val);
                    }}
                  />
                </div>

                <div style={{ maxWidth: "100px", width: "33%" }}>
                  <Dropdown className="mdd-dropdown-time">
                    <Dropdown.Toggle
                      style={{ maxHeiht: 37, fontSize: "15px" }}
                      className="w-100 dropdown-dashboard-time"
                      id="dropdown-mdd-time"
                    >
                      minutos
                    </Dropdown.Toggle>

                    <Dropdown.Menu
                      rootCloseEvent={"click"}
                      className="div-drop-sort"
                    >
                      {["minutes"].map((item) => {
                        return (
                          <Dropdown.Item
                            style={{
                              color: Colors.brand_blue,
                            }}
                            onClick={() => {}}
                          >
                            minutos
                          </Dropdown.Item>
                        );
                      })}
                    </Dropdown.Menu>
                  </Dropdown>
                </div>
              </Col>
            </Row>
          )}
          <div>
            <div>
              {schedules.map((item, index) => {
                var positionItem = listPosition.find(
                  (i) => i.value == item.position_id
                );
                return (
                  <Row key={index} style={{ marginBottom: 16 }}>
                    <Col xs={4} md={5} lg={2}>
                      {index == 0 && (
                        <Form.Label className="field-label">Horário</Form.Label>
                      )}
                      <div
                        style={{
                          borderColor: item.errorTime
                            ? Colors.brand_red
                            : "#ddd",
                        }}
                        className="default-input"
                      >
                        <Icon
                          color={Colors.darker_gray}
                          size={24}
                          icon="clock"
                          className="mr-2"
                        />
                        <InputMask
                          style={{ border: "none", width: "80%" }}
                          mask={"99:99"}
                          maskChar={null}
                          type="text"
                          placeholder="00:00"
                          value={item.schedule}
                          onChange={(e) => HandlerTime(e.target.value, item.id)}
                        />
                      </div>
                    </Col>

                    <Col xs={4} md={5} lg={9}>
                      {index == 0 && (
                        <Form.Label className="field-label">Posição</Form.Label>
                      )}
                      <Select
                        captureMenuScroll={false}
                        menuPlacement={
                          index == schedules.length - 1 ? "top" : "bottom"
                        }
                        noOptionsMessage={() => "Indisponível"}
                        classNamePrefix="react-select"
                        placeholder={"Selecionar"}
                        options={listPosition}
                        isClearable={false}
                        value={positionItem}
                        onChange={(obj) => {
                          HandlerPosition(obj, item.id);
                        }}
                        styles={{
                          control: (provided, state) => ({
                            ...provided,
                            borderColor: `${
                              item.errorPosition && Colors.brand_red
                            } !important`,
                          }),
                        }}
                      />
                    </Col>

                    <Col xs={4} md={2} lg={1}>
                      <button
                        style={{ marginTop: index == 0 ? 28 : 0 }}
                        title="Remover horário"
                        className="btn-remove"
                        disabled={index == 0 ? true : false}
                        onClick={() => {
                          if (item.register) {
                            //Verifica se já foi salvo
                            //Se sim da push no array pra deletar de fato na hora de salvar
                            removeSchedule.push(item);
                          }

                          var newList = schedules.filter((it, ind) => {
                            if (item.id != it.id) {
                              return it;
                            }
                          });

                          setSchedules(newList);
                        }}
                      >
                        <Icon
                          color={
                            index == 0 ? Colors.light_gray : Colors.darkest_gray
                          }
                          size={15}
                          icon="minus"
                        />
                      </button>
                    </Col>
                  </Row>
                );
              })}
              <button
                className="d-flex align-items-center mt-3 reset-button"
                onClick={() =>
                  setSchedules([
                    ...schedules,
                    { id: GenerateId(), schedule: "", position_id: 0 },
                  ])
                }
              >
                <Icon
                  color={Colors.brand_pink}
                  size={15}
                  icon="plus"
                  className="mr-2"
                />
                <p className="mb-0 add-other-text">Adicionar horário</p>
              </button>
            </div>

            <div className="mt-5">
              <button
                onClick={() => SaveSchedules()}
                disabled={
                  (VerifyFields() == true ? false : true) ||
                  loadingBtn === "flex"
                }
                className="modal-footer-btn btn-confirm float-right"
                style={{
                  backgroundColor:
                    VerifyFields() == true
                      ? Colors.brand_pink
                      : Colors.lighter_gray,
                  width: 250,
                }}
              >
                <p
                  className="mb-0"
                  style={{
                    color: VerifyFields() == true ? "white" : Colors.dark_gray,
                    display: loadingBtn == "none" ? "flex" : "none",
                  }}
                >
                  Salvar protocolo de decúbito
                </p>

                <Spinner
                  style={{ display: loadingBtn }}
                  animation="border"
                  role="status"
                  variant="light"
                  size="sm"
                />
              </button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}

export default ProtocoloDecubito;
