import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import {
  Col,
  Container,
  Row,
  Table,
  Spinner,
  Form,
  Tooltip,
  OverlayTrigger,
} from "react-bootstrap";
import MainNavbar from "../../../components/Nav/MainNavbar";
import Select from "react-select";

import Colors from "../../../styles/Colors";
import "../../../styles/global.css";
import "./styles.css";

import api from "../../../services/api";
import { Show_Alert } from "../../../store/actions/alert";
import VerifyPwWithUser from "../../../components/Modals/VerifyPwWithUser";
import {
  userHasPermission,
  userWithoutPermission,
} from "../../../functions/utils";

function AvaliacaoRisco({ history }) {
  // Dados de usuário
  const dispatch = useDispatch();
  const user = useSelector((state) => state.user);
  const unit = useSelector((state) => state.unit);

  // Seleção/Dados
  const [listAvaliations, setListAvaliations] = useState([]);
  const [patient, setPatient] = useState(undefined);
  const [patients, setPatients] = useState([]);
  const [escore, setEscore] = useState(0);
  const [escoreColor, setEscoreColor] = useState(Colors.lightest_gray);
  const [escoreFont, setEscoreFont] = useState(Colors.dark_gray);
  const [escoreTexto, setEscoreTexto] = useState("Selecione as opções acima");
  const [enableSave, setEnableSave] = useState(false);
  const [newPatient, setNewPatient] = useState(undefined);

  // Avaliação
  const [resultadoNome, setResultadoNome] = useState(undefined);
  const [resultado, setResultado] = useState(undefined);
  const [avaliation, setAvaliation] = useState([]);

  // Toggles
  const [percepcao, setPercepcao] = useState(0);
  const [umidade, setUmidade] = useState(0);
  const [atividade, setAtividade] = useState(0);
  const [mobilidade, setMobilidade] = useState(0);
  const [nutricao, setNutricao] = useState(0);
  const [friccao, setFriccao] = useState(0);

  // Flags
  const [addPatient, setAddPatient] = useState(false);
  const [loadingBtn, setLoadingBtn] = useState("none");

  const [listUsers, setListUsers] = useState([]);
  const [userChooser, setUserChooser] = useState({
    label: "Selecionar",
    value: 0,
  });
  const [showPw, setShowPw] = useState(false);

  function RenderTooltip(text) {
    return <Tooltip id="tooltip-top">{text}</Tooltip>;
  }

  useEffect(() => {
    //Se permission_id for 1,2 seta quem ta logado direto no responsavel
    if (userHasPermission(user)) {
      setUserChooser({ label: user.dados.name, value: user.dados.id });
    }
  }, []);

  useEffect(() => {
    function LoadUsers() {
      api
        .get(`/users`, {
          params: {
            permission_id: "1,2",
            unit_id: unit.selecionado?.id,
            status: 1,
          },
        })
        .then((response) => {
          if (response.status == 200) {
            console.log("LoadUsers");
            console.log(response.data);

            var array = [{ label: "Selecionar", value: 0 }];
            response.data.map((item) => {
              array.push({ label: item.name, value: item.id });
            });
            setListUsers(array);
          }
        })
        .catch((error) => {
          console.log(error);
        });
    }

    if (userWithoutPermission(user) && patient != undefined) {
      LoadUsers();
    }
  }, [patient]);

  // Carregamento de dados
  useEffect(() => {
    LoadPatients();
    LoadAvaliations();
  }, [newPatient, unit]);

  // Carregar lista de pacientes
  function LoadPatients() {
    return api
      .get(`/patients`, {
        params: {
          unit_id: unit.selecionado?.id,
          page: 1,
          pageSize: 999,
          status: 1,
          orders: "name",
          orders_type: "ASC",
        },
      })
      .then((response) => {
        if (response.status == 200) {
          // console.log("LoadPatients");
          // console.log(response.data);

          var array = [];
          response.data.data.map((item) => {
            array.push({
              key: item.id,
              label: item.name,
              value: item.id,
            });
          });
          setPatients(array);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  }

  // Carregar avaliações
  function LoadAvaliations() {
    api
      .get(`/avaliations`)
      .then((response) => {
        if (response.status == 200) {
          console.log("LoadAvaliations");
          console.log(response.data);
          setListAvaliations(response.data[0]);
        }
      })
      .catch((error) => {
        console.log("LoadAvaliations error " + error);
      });
  }

  // Modificar avaliação específica
  function SetarAvaliation(avaliation_id, factor, score) {
    var obj = {
      avaliation_id,
      factor,
      score,
    };

    var found = avaliation.find((item) => item.avaliation_id == avaliation_id);
    if (found != undefined) {
      var newList = avaliation.filter(
        (item) => item.avaliation_id != avaliation_id
      );
      setAvaliation([...newList, obj]);
    } else {
      setAvaliation([...avaliation, obj]);
    }
  }

  // Atualizar escore
  useEffect(() => {
    // Calcula escore
    var new_escore = 0;

    if (avaliation.length > 0) {
      avaliation.map((item) => {
        new_escore += item.score;
      });
    }

    // Rank do escore
    if (new_escore > 0 && new_escore <= 9) {
      setResultadoNome("Risco muito alto");
      setEscoreTexto("Escore " + new_escore + " - Risco muito alto");
      setEscoreColor(Colors.brand_red);
      setEscoreFont("#fff");
    } else if (new_escore >= 10 && new_escore <= 12) {
      setResultadoNome("Risco alto");
      setEscoreTexto("Escore " + new_escore + " - Risco alto");
      setEscoreColor(Colors.brand_orange);
      setEscoreFont("#fff");
    } else if (new_escore >= 13 && new_escore <= 14) {
      setResultadoNome("Risco moderado");
      setEscoreTexto("Escore " + new_escore + " - Risco moderado");
      setEscoreColor(Colors.brand_yellow);
      setEscoreFont("#fff");
    } else if (new_escore >= 15 && new_escore <= 18) {
      setResultadoNome("Risco baixo");
      setEscoreTexto("Escore " + new_escore + " - Risco baixo");
      setEscoreColor(Colors.brand_green);
      setEscoreFont("#fff");
    } else if (new_escore >= 19) {
      setResultadoNome("Fora de risco");
      setEscoreTexto("Escore " + new_escore + " - Fora de risco");
      setEscoreColor(Colors.brand_blue);
      setEscoreFont("#fff");
    }

    setResultado(new_escore);
  }, [avaliation]);

  useEffect(() => {
    // Ativar botão de salvar
    if (
      avaliation.length === 6 &&
      patient != undefined
      // && userChooser.value > 0
    ) {
      setEnableSave(true);
    } else {
      setEnableSave(false);
    }
  }, [patient, avaliation, userChooser]);

  // Salvar avaliação
  function Salvar(user_id) {
    if (!enableSave) return false;
    setLoadingBtn("flex");
    api
      .post(`/patients/${patient.value}/avaliations`, {
        user_id,
        name_result: resultadoNome,
        result: resultado,
        values: avaliation,
      })
      .then((response) => {
        if (response.status == 200) {
          dispatch(
            Show_Alert({
              type: "success",
              msg: "A avaliação de risco foi cadastrada com sucesso.",
            })
          );
          history.push({ pathname: "/" });
        }
      })
      .catch((error) => {
        console.log("SaveAvaliação error: ");
        console.log(error);
      })
      .finally(() => {
        setLoadingBtn("none");
      });
  }

  return (
    <div>
      <MainNavbar history={history} />

      {showPw && (
        <VerifyPwWithUser
          handler_show_pw={setShowPw}
          show_pw={showPw}
          handler_request={Salvar}
          handler_hide_tec_users={true}
        />
      )}

      <Container
        fluid
        className="bg-white pb-3"
        style={{ paddingRight: 100, paddingLeft: 100 }}
      >
        <Row
          className="pt-5"
          style={{ justifyContent: "flex-start", alignItems: "center" }}
        >
          <Col>
            <div className="page-title">
              Nova avaliação de risco - Escala de Braden
            </div>
          </Col>
        </Row>

        <Row className="justify-content-start mt-4">
          <Col xs={12} md={6} lg={4}>
            <Row className="mx-0 justify-content-between align-items-center">
              <label className="field-label">Paciente</label>
              <div
                className="pink-link mb-0"
                onClick={() => {
                  history.push({
                    pathname: "/Pacientes",
                    state: { addPatient: true },
                  });
                }}
              >
                + Novo paciente
              </div>
            </Row>

            <Select
              captureMenuScroll={false}
              classNamePrefix="react-select"
              placeholder={"Selecionar paciente"}
              options={patients}
              isClearable={false}
              value={patient}
              onChange={(p) => {
                setPatient(p);
              }}
            />
          </Col>

          {/* {user.dados.permission_id > 3 && (
            <Col xs={12} md={6} lg={4}>
              <div>
                <Form.Label className="field-label">Responsável</Form.Label>
                <Select
                  captureMenuScroll={false}
                  classNamePrefix="react-select"
                  placeholder={"Selecionar"}
                  options={listUsers}
                  isClearable={false}
                  value={userChooser}
                  onChange={(item) => {
                    setUserChooser(item);
                    setShowPw(true);
                  }}
                />
              </div>
            </Col>
          )} */}
        </Row>

        <Table className="responsive mt-4 mb-3 table-borderless">
          <thead>
            <tr>
              <th>&nbsp;</th>
              <th className="text-center cell-title">1</th>
              <th className="text-center cell-title">2</th>
              <th className="text-center cell-title">3</th>
              <th className="text-center cell-title">4</th>
            </tr>
          </thead>
          <tbody>
            {listAvaliations.id != undefined &&
              listAvaliations.avaliations.map((item, index) => {
                return (
                  <tr>
                    <td className="cell-title align-middle">{item.name}</td>

                    {item.values.map((i) => {
                      var found = avaliation.find(
                        (a) => a.avaliation_id == item.id && a.score == i.score
                      );
                      return (
                        <td>
                          <OverlayTrigger
                            placement={index == 0 ? "bottom" : "top"}
                            delay={{ show: 250, hide: 10 }}
                            overlay={RenderTooltip(i.description)}
                          >
                            <div
                              className={
                                found != undefined
                                  ? "avaliacao-marker active"
                                  : "avaliacao-marker"
                              }
                              onClick={() => {
                                if (i.id == 24) return false;
                                SetarAvaliation(item.id, item.name, i.score);
                              }}
                            >
                              {i.name}
                            </div>
                          </OverlayTrigger>
                        </td>
                      );
                    })}
                  </tr>
                );
              })}
          </tbody>
        </Table>

        <Row style={{ marginBottom: "20vh" }}>
          <Col>
            <div className="info-blue">
              A pontuação pode ir de 6 a 23. Classificação: Risco muito alto (6
              a 9 pontos), Risco alto (10 a 12 pontos), Risco moderado (13 a 14
              pontos), Risco baixo (15 a 18 pontos) e Fora de risco (19 a 23
              pontos).
              <br />
              A contagem de pontos baixa indica uma baixa habilidade funcional,
              estando o paciente em alto risco para desenvolver a lesão por
              pressão.
              <br />
              Pacientes adultos hospitalizados com uma contagem menor ou igual a
              18 pontos, são considerados de risco e serão emitidos avisos para
              a mudança de decúbito.
            </div>
          </Col>
        </Row>

        <div className="fixed-bottom-bar">
          <hr className="footer-line mt-0" />

          <Row className="justify-content-between bottom-button-row">
            <Col
              xs={6}
              md={4}
              lg={3}
              className="text-left btn-escore"
              style={{
                backgroundColor: escoreColor,
                color: escoreFont,
              }}
            >
              {escoreTexto}
            </Col>
            <Col
              onClick={() => {
                if (
                  (user.dados.permission_id == 1 ||
                    user.dados.permission_id == 2 ||
                    user.dados.permission_id >= 6) &&
                  user.dados.permission_id !== 8 &&
                  user.dados.permission_id !== 9
                ) {
                  Salvar(user.dados.id);
                  return false;
                }

                setShowPw(true);
              }}
              xs={6}
              md={4}
              lg={3}
              className={
                enableSave
                  ? "text-right default-btn btns-avaliacao"
                  : "text-right default-btn disabled btns-avaliacao"
              }
            >
              <p
                style={{
                  display: loadingBtn == "none" ? "flex" : "none",
                }}
              >
                Salvar avaliação
              </p>
              <Spinner
                style={{ display: loadingBtn }}
                animation="border"
                role="status"
                variant="light"
                size="sm"
              />
            </Col>
          </Row>
        </div>
      </Container>
    </div>
  );
}

export default AvaliacaoRisco;
