import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import Ws from "@adonisjs/websocket-client";
import {
  Col,
  Container,
  Row,
  Tooltip,
  OverlayTrigger,
  Spinner,
} from "react-bootstrap";
import MainNavbar from "../../../components/Nav/MainNavbar";
import ModalAvisos from "./ModalAvisos";
import ModalDecubito from "./ModalDecubito";
import ModalChecklist from "./ModalChecklist";

import Colors from "../../../styles/Colors";
import "../../../styles/global.css";
import "./styles.css";

import ModalMdd from "../../../components/Modals/ModalMdd";
import ModalSensor from "../../../components/Modals/ModalSensor";
import Icon from "../../../functions/Icon";
import IconAchievements from "../../../functions/IconAchievements";
import api from "../../../services/api";
import { Show_Alert } from "../../../store/actions/alert";
import {
  HexToRgbA,
  ConvertRuntime,
  GetTag,
  GetTagColor,
  GetTagName,
  adjustSeconds,
  hospitalIsFullType,
} from "../../../functions/utils";
import moment from "moment";
import ListPatientsOverview from "../../../components/Modals/ListPatientsOverview";
import orderSensor from "../OrderSensor";
import ModalPatientsRisks from "../../Relatorios/ModalData/ModalPatientsRisks";

const iconFisrt = require("../../../assets/img/first.png");
const iconSecond = require("../../../assets/img/second.png");
const iconThird = require("../../../assets/img/third.png");

//lista original info relatorios (hoje/mes)
var listOverview = null;

function Relatorio(props) {
  const {
    relatorioTab,
    overview,
    history,
    handler_show_modal,
    handler_type_clicked,
  } = props;
  const dispatch = useDispatch();
  const [patientIds, setPatientIds] = useState([]);
  const [showModalPatients, setShowModalPatients] = useState(false);

  function GoTo(type) {
    //Abre modal
    if (relatorioTab == "Mes") {
      setPatientIds(overview[type]);
      handler_type_clicked(type);
      if (overview.loaded) {
        setShowModalPatients(true);
      }
      return false;
    }

    //Redireciona pra lista de pacientes
    //Passando parametros de filtro e ordenação
    var obj = {
      score: null,
      has_wound: null,
      type: null,
    };

    let patientState = { filter_overview: obj };

    dispatch({
      type: "RESET_TAB_PATIENT_PAGE",
    });

    if (type == "patientsWithRisk") {
      obj.score = true;
    } else if (type == "patientsWithIncidencePressureWounds") {
      obj.type = "incidence";
      obj.score = true;
    } else if (type == "patientsWithPressureWounds") {
      obj.has_wound = 1;
      patientState.discharge = false;
    } else if (type == "pressureWoundsAdmission") {
      obj.type = "admission";
    } else if (type == "pressureWoundsIncidence") {
      obj.type = "incidence";
    }

    history.push({
      pathname: "/Pacientes",
      state: patientState,
    });
  }

  function CalculateWoundIncidence(
    patientsWithIncidencePressureWounds,
    patientsWithRisk
  ) {
    if (patientsWithRisk.length == 0) {
      return 0;
    }
    return (
      (patientsWithIncidencePressureWounds.length / patientsWithRisk.length) *
      100
    ).toFixed(2);
  }

  function RenderTooltip(percentage, patients_wounds, patients_risks) {
    return (
      <Tooltip id="tooltip-top">
        <div>
          <p>{patients_wounds} pacientes com lesões incididas /</p>
          <p>
            {patients_risks} pacientes de risco = {percentage} %
          </p>
        </div>
      </Tooltip>
    );
  }

  return (
    <div className="d-flex flex-wrap" style={{ minHeight: 145 }}>
      {!overview.loaded ? (
        <div className="container-loading-report mt-3 d-flex justify-content-center align-items-center w-100 h-100">
          <Spinner animation="border" variant="primary" />
        </div>
      ) : (
        <>
          <Col
            className="mb-2"
            xs={6}
            md={4}
            onClick={() => {
              GoTo("patientsWithRisk");
            }}
          >
            <div className="d-flex align-items-center">
              <Icon color={Colors.brand_blue} size={24} icon="warning" />
              <p className="title-overview">
                {overview.patientsWithRisk.length}
              </p>
            </div>
            <p className="subtitle-overview">Pacientes de risco</p>
          </Col>

          <Col
            className="mb-2"
            xs={6}
            md={4}
            onClick={() => {
              GoTo("patientsWithRiskCurrentWoundsIncidence");
            }}
          >
            <div className="d-flex align-items-center">
              <Icon color={Colors.brand_blue} size={24} icon="warning" />
              <p className="title-overview">
                {overview.patientsWithRiskCurrentWoundsIncidence.length}
              </p>
            </div>
            <p className="subtitle-overview">
              Pacientes de risco com lesões incididas
            </p>
          </Col>

          <Col xs={6} md={4} className="mb-2">
            <OverlayTrigger
              placement="top"
              delay={{ show: 250, hide: 10 }}
              overlay={RenderTooltip(
                CalculateWoundIncidence(
                  overview.patientsWithRiskCurrentWoundsIncidence,
                  overview.patientsWithRisk
                ),
                overview.patientsWithRiskCurrentWoundsIncidence.length,
                overview.patientsWithRisk.length
              )}
            >
              <div>
                <>
                  <div className="d-flex align-items-center">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      enable-background="new 0 0 24 24"
                      height="24"
                      viewBox="0 0 24 24"
                      width="24"
                    >
                      <g>
                        <rect fill="none" height="24" width="24" x="0" />
                      </g>
                      <g>
                        <g>
                          <path
                            fill={Colors.brand_blue}
                            d="M23,8c0,1.1-0.9,2-2,2c-0.18,0-0.35-0.02-0.51-0.07l-3.56,3.55C16.98,13.64,17,13.82,17,14c0,1.1-0.9,2-2,2s-2-0.9-2-2 c0-0.18,0.02-0.36,0.07-0.52l-2.55-2.55C10.36,10.98,10.18,11,10,11c-0.18,0-0.36-0.02-0.52-0.07l-4.55,4.56 C4.98,15.65,5,15.82,5,16c0,1.1-0.9,2-2,2s-2-0.9-2-2s0.9-2,2-2c0.18,0,0.35,0.02,0.51,0.07l4.56-4.55C8.02,9.36,8,9.18,8,9 c0-1.1,0.9-2,2-2s2,0.9,2,2c0,0.18-0.02,0.36-0.07,0.52l2.55,2.55C14.64,12.02,14.82,12,15,12c0.18,0,0.36,0.02,0.52,0.07 l3.55-3.56C19.02,8.35,19,8.18,19,8c0-1.1,0.9-2,2-2S23,6.9,23,8z"
                          />
                        </g>
                      </g>
                    </svg>
                    <p className="title-overview">
                      {CalculateWoundIncidence(
                        overview.patientsWithRiskCurrentWoundsIncidence,
                        overview.patientsWithRisk
                      )}{" "}
                      %
                    </p>
                  </div>
                  <p className="subtitle-overview">Incidência da lesão</p>
                </>
              </div>
            </OverlayTrigger>
          </Col>

          <Col
            xs={6}
            md={4}
            onClick={() => {
              GoTo("patientsWithPressureWounds");
            }}
          >
            <div className="d-flex align-items-center">
              <Icon color={Colors.brand_blue} size={24} icon="band-aid" />
              <p className="title-overview">
                {overview.patientsWithPressureWounds.length}
              </p>
            </div>
            <p className="subtitle-overview">Pacientes com lesão</p>
          </Col>

          <Col
            xs={6}
            md={4}
            onClick={() => {
              GoTo("patientsWithRiskCurrentWoundsAdmission");
            }}
          >
            <div className="d-flex align-items-center">
              <Icon color={Colors.brand_blue} size={24} icon="enter" />
              <p className="title-overview">
                {overview.patientsWithRiskCurrentWoundsAdmission.length}
              </p>
            </div>
            <p className="subtitle-overview">Lesões admitidas</p>
          </Col>

          <Col
            xs={6}
            md={4}
            onClick={() => {
              GoTo("patientsWithRiskCurrentWoundsIncidence");
            }}
          >
            <div className="d-flex align-items-center">
              <Icon color={Colors.brand_blue} size={24} icon="hospital" />
              <p className="title-overview">
                {overview.patientsWithRiskCurrentWoundsIncidence.length}
              </p>
            </div>
            <p className="subtitle-overview">Lesões incididas</p>
          </Col>
        </>
      )}
      {showModalPatients && (
        <ModalPatientsRisks
          handler_show_modal={setShowModalPatients}
          show_modal={showModalPatients}
          title={"Pacientes"}
          removeColumns={[
            "Estágio",
            "Data de confirmação",
            "Admissão",
            "Alta",
            "Tempo monitorado",
          ]}
          ids={patientIds}
          type="indicators"
        />
      )}
    </div>
  );

  // return (
  //   <>
  //     <Row className="mx-1 mb-1">
  //       <Col xs={6} md={6} lg={6}>
  //         <Row className="justify-content-start align-items-center">
  //           <Icon color={Colors.brand_blue} size={24} icon="warning" />
  //           <div className="big-stat ml-2">{overview.patients_risks}</div>
  //         </Row>
  //         <Row className="name-stat">Pacientes de risco</Row>
  //       </Col>

  //       <Col xs={6} md={6} lg={6} className="mb-2">
  //         <Row className="justify-content-start align-items-center">
  //           <Icon color={Colors.brand_blue} size={24} icon="hospital" />
  //           <div className="big-stat ml-2">{overview.wounds_incidence}</div>
  //         </Row>
  //         <Row className="name-stat">Lesões incididas</Row>
  //       </Col>

  //       {relatorioTab == "Hoje" && (
  //         <Col xs={6} md={6} lg={6} className="mb-2">
  //           <Row className="justify-content-start align-items-center">
  //             <Icon color={Colors.brand_blue} size={24} icon="band-aid" />
  //             <div className="big-stat ml-2">{overview.patients_wounds}</div>
  //           </Row>
  //           <Row className="name-stat">Pacientes com lesão</Row>
  //         </Col>
  //       )}

  //       {relatorioTab == "Mes" && (
  //         <Col xs={6} md={6} lg={6} className="mb-2">
  //           <Row className="justify-content-start align-items-center">
  //             <svg
  //               xmlns="http://www.w3.org/2000/svg"
  //               enable-background="new 0 0 24 24"
  //               height="24"
  //               viewBox="0 0 24 24"
  //               width="24"
  //             >
  //               <g>
  //                 <rect fill="none" height="24" width="24" x="0" />
  //               </g>
  //               <g>
  //                 <g>
  //                   <path
  //                     fill={Colors.brand_blue}
  //                     d="M23,8c0,1.1-0.9,2-2,2c-0.18,0-0.35-0.02-0.51-0.07l-3.56,3.55C16.98,13.64,17,13.82,17,14c0,1.1-0.9,2-2,2s-2-0.9-2-2 c0-0.18,0.02-0.36,0.07-0.52l-2.55-2.55C10.36,10.98,10.18,11,10,11c-0.18,0-0.36-0.02-0.52-0.07l-4.55,4.56 C4.98,15.65,5,15.82,5,16c0,1.1-0.9,2-2,2s-2-0.9-2-2s0.9-2,2-2c0.18,0,0.35,0.02,0.51,0.07l4.56-4.55C8.02,9.36,8,9.18,8,9 c0-1.1,0.9-2,2-2s2,0.9,2,2c0,0.18-0.02,0.36-0.07,0.52l2.55,2.55C14.64,12.02,14.82,12,15,12c0.18,0,0.36,0.02,0.52,0.07 l3.55-3.56C19.02,8.35,19,8.18,19,8c0-1.1,0.9-2,2-2S23,6.9,23,8z"
  //                   />
  //                 </g>
  //               </g>
  //             </svg>

  //             {/* <Icon color={Colors.brand_blue} size={24} icon="band-aid" /> */}
  //             <div className="big-stat ml-2">
  //               {overview.incidence_percentage} %
  //             </div>
  //           </Row>
  //           <Row className="name-stat">Incidência da lesão</Row>
  //         </Col>
  //       )}

  //       <Col xs={6} md={6} lg={6}>
  //         <Row className="justify-content-start align-items-center">
  //           <Icon color={Colors.brand_blue} size={24} icon="enter" />
  //           <div className="big-stat ml-2">{overview.wounds_admission}</div>
  //         </Row>
  //         <Row className="name-stat">Lesões admitidas</Row>
  //       </Col>
  //     </Row>
  //   </>
  // );
}

function MudancasNew(props) {
  const { handler_reload, reload, data, history, handler_loading } = props;

  const [dados, setDados] = useState([]);
  const [showModalMdd, setShowModalMdd] = useState(false);
  const [showModalSensor, setShowModalSensor] = useState(false);
  const [updateTime, setUpdateTime] = useState(false);
  const [chooser, setChooser] = useState({});
  const [isProgressive, setIsProgressive] = useState(false);

  function Calculate(datetime) {
    var diffS = "";
    var isBefore = false;
    if (moment().isBefore(moment(datetime))) {
      isBefore = true;
      diffS = moment(datetime).diff(moment(), "minutes");
    } else {
      diffS = moment().diff(moment(datetime), "minutes");
    }
    var newDiff = `${!isBefore ? "-" : ""}${ConvertRuntime(diffS)}`;

    // console.log("======================");
    // console.log("DATETIME " + datetime);
    // console.log("diffS " + diffS);
    // console.log("newDiff " + newDiff);
    // console.log("======================");

    return { diffS, newDiff, isBefore };
  }

  useEffect(() => {
    var newList = data.map((item, index) => {
      var result = Calculate(
        item.tag != "realized"
          ? item.datetime_schedule
          : item.next_datetime_position
      );
      //item.tag = GetTag(item.different, isBefore, item.tag);

      //Tempo na posicao
      if (item.beacon?.id != undefined) {
        if (item.beacon.time != "00:00:00") {
          item.beacon.time = adjustSeconds(item.beacon.time, 1, "add");
        }
      }

      if (item.time_suspend == "- 1 min") {
        handler_reload(!reload);
      }

      return item;
    });
    setDados(newList);

    setTimeout(() => {
      setUpdateTime(!updateTime);
    }, 1000); //60000
  }, [updateTime, data]);

  useEffect(() => {
    function LoadCheckIfHospitalIsProgressiveMdd() {
      api
        .get("/default-settings/hospitals/progressive-time")
        .then((response) => {
          if (response.status == 200) {
            setIsProgressive(response.data.is_progressive);
          }
        })
        .catch((error) => {
          console.log("LoadCheckIfHospitalIsProgressiveMdd error " + error);
        });
    }
    LoadCheckIfHospitalIsProgressiveMdd();
  }, []);

  function VerifyPosition(notification) {
    var ret = true;
    if (notification.tag == "realized") {
      ret = false;

      if (notification.color == "#FF7B49") {
        return true;
      }
    }
    return ret;
  }

  function showNextPositionInCard(item) {
    if (item && isProgressive) {
      if (item.color == "#FFC82C" || item.color == "#FF7B49") {
        return true;
      }
      return false;
    }
    return true;
  }

  function showArrow(item) {
    return (
      showNextPositionInCard(item) &&
      item.beacon?.position_abb_actual != item?.beacon?.position_name_abb &&
      VerifyPosition(item) &&
      item?.beacon?.position_name_abb != ""
    );
  }

  return (
    <>
      {showModalMdd && (
        <ModalMdd
          data={chooser}
          beacon={chooser.beacon}
          handler_modal={setShowModalMdd}
          show_modal={showModalMdd}
          handler_reload={handler_reload}
          handler_loading={handler_loading}
          handlerWaitMddLoad={() => {}}
          reload={reload}
          position_actual={chooser?.beacon?.position_actual}
          position_to={chooser?.beacon?.position_name}
          notification_id={chooser.notification_id}
          patient_name={chooser.name}
          patient_id={chooser.patient_id}
          room={chooser.room}
          subroom={chooser.subroom}
          tag={chooser.tag}
          different={chooser?.different}
          beacon_time={chooser?.beacon?.time}
          tag_color={chooser?.color}
          suspended={chooser.suspended}
          isProgressive={chooser?.isProgressive}
          timeProgressive={chooser?.timeProgressive}
          defaultTimeProgressive={chooser?.defaultTimeProgressive}
          history={history}
        />
      )}

      {showModalSensor && (
        <ModalSensor
          data={chooser}
          handler_modal={setShowModalSensor}
          show_modal={showModalSensor}
          link={chooser.link_beacon}
          handler_reload={handler_reload}
          reload={reload}
          patient_id={chooser.patient_id}
          room={chooser.room}
          subroom={chooser.subroom}
          date_birth={chooser.date_birth}
          age={chooser.age}
          patient_name={chooser.name}
          beacon_name={chooser?.beacon?.name}
          beacon_id={chooser?.beacon?.id}
          unit_id={chooser.unit_id}
        />
      )}

      <Row className="justify-content-start align-items-center">
        {dados.map((item, index) => {
          if (item.beacon?.id != undefined && !item.link_beacon) {
            return (
              <Col key={index} xs={12} md={4} xl={3} className="my-2">
                <div
                  className="mdd-card-sensor"
                  onClick={() => {
                    // setChooser(item);
                    // setShowModalMdd(true);
                    setChooser(item);
                    if (item.beacon?.id == undefined || item.unlink_beacon) {
                      setShowModalSensor(true);
                    } else {
                      setShowModalMdd(true);
                    }
                  }}
                >
                  <div
                    style={{
                      paddingLeft: 15,
                      paddingRight: 15,
                      paddingTop: 15,
                    }}
                  >
                    <div className="d-flex justify-content-between mb-2">
                      <p className="card-name card-name-mdd">
                        {item?.initials}
                      </p>

                      {!item.unlink_beacon &&
                        (item.tag != "suspend" ? (
                          <div
                            className="mdd-ball"
                            style={{
                              //backgroundColor: GetTagColor(item.tag),
                              backgroundColor: item.color,
                            }}
                          />
                        ) : (
                          <Icon
                            icon={
                              item.suspended.is_paused ? "no-time" : "bell-off"
                            }
                            size={20}
                            color={
                              item.suspended.is_paused ? "" : Colors.dark_gray
                            }
                          />
                        ))}
                    </div>

                    <div className="d-flex justify-content-between">
                      <div className="card-quarto card-quarto-mdd mb-3">
                        {item.room == null && item.subroom}
                        {item.room != null && item.room + " | " + item.subroom}
                      </div>
                      {!item.unlink_beacon && item?.beacon.signal_beacon && (
                        <p className="mdd-time">
                          {item.tag != "suspend"
                            ? item.different
                            : item.suspended?.minutes === "INDETERMINATE"
                            ? "Indeterm."
                            : item.time_suspend}
                        </p>
                      )}
                    </div>
                  </div>

                  {!item.unlink_beacon ? (
                    <>
                      <div
                        style={{
                          paddingLeft: 16,
                          paddingRight: 16,
                          paddingBottom: 16,
                        }}
                        className="d-flex justify-content-between mt-3"
                      >
                        {item.beacon.signal_beacon ? (
                          <div className="d-flex align-items-center">
                            <div className="d-flex  align-items-center">
                              <Icon
                                icon={
                                  item.beacon.position_corrected
                                    ? "beds"
                                    : "warning"
                                }
                                size={18}
                                color={
                                  item.beacon.position_color == "#666666"
                                    ? Colors.brand_blue
                                    : item.beacon.position_color
                                }
                              />
                              <p
                                className="mdd-footer-text"
                                style={{ color: item.beacon.position_color }}
                              >
                                {item.beacon.position_corrected &&
                                item.tag != "soon"
                                  ? item.beacon?.position_actual
                                  : item.beacon?.position_abb_actual}
                              </p>
                            </div>

                            {showArrow(item) && (
                              <>
                                <div className="mdd-arrow">
                                  <Icon
                                    icon={"arrow-right-1"}
                                    size={20}
                                    color={Colors.dark_gray}
                                  />
                                </div>

                                <div className="d-flex align-items-center">
                                  <Icon
                                    icon={"refresh"}
                                    size={18}
                                    color={Colors.brand_blue}
                                  />
                                  <p className="mdd-footer-text">
                                    {item?.beacon?.position_name_abb}
                                  </p>
                                </div>
                              </>
                            )}
                          </div>
                        ) : (
                          <div className="d-flex align-items-center">
                            <Icon
                              icon={"warning"}
                              size={18}
                              color={Colors.new_orange}
                            />
                            <p
                              className="mdd-footer-text"
                              style={{
                                color: Colors.new_orange,
                              }}
                            >
                              SEM SINAL
                            </p>
                          </div>
                        )}

                        {item.beacon.signal_beacon && (
                          <div className="d-flex align-items-center">
                            <Icon
                              icon={
                                item.beacon.angle_corrected
                                  ? "angle"
                                  : "warning"
                              }
                              size={18}
                              color={
                                item.beacon.angle_corrected
                                  ? Colors.brand_blue
                                  : Colors.new_orange
                              }
                            />
                            <p
                              className="mdd-footer-text"
                              style={{
                                color: item.beacon.angle_corrected
                                  ? Colors.darkest_gray
                                  : Colors.new_orange,
                              }}
                            >
                              {item.beacon.angle}°
                            </p>
                          </div>
                        )}
                      </div>
                    </>
                  ) : (
                    <button className="btn-desvincular">
                      <p>Desvincular sensor</p>
                    </button>
                  )}
                </div>
              </Col>
            );
          } else {
            return (
              <Col xs={12} md={4} xl={3} className="my-2">
                <div
                  className="mdd-card-sensor"
                  onClick={() => {
                    setChooser(item);
                    setShowModalSensor(true);
                  }}
                >
                  <div
                    style={{
                      paddingLeft: 15,
                      paddingRight: 15,
                      paddingTop: 15,
                    }}
                  >
                    <div className="d-flex justify-content-between mb-2">
                      <p className="card-name card-name-mdd">
                        {item?.initials}
                      </p>
                    </div>

                    <div className="d-flex justify-content-between">
                      <div className="card-quarto card-name-mdd mb-3">
                        {item.room == null && item.subroom}
                        {item.room != null && item.room + " | " + item.subroom}
                      </div>
                    </div>
                  </div>

                  <button className="btn-vincular">
                    <p>Vincular sensor</p>
                  </button>
                </div>
              </Col>
            );
          }
        })}
      </Row>
    </>
  );
}

function Vinculacao(props) {
  const { handler_reload, reload, data } = props;

  const [dados, setDados] = useState(data);
  const [showModalMdd, setShowModalMdd] = useState(false);
  const [showModalSensor, setShowModalSensor] = useState(false);
  const [updateTime, setUpdateTime] = useState(false);
  const [chooser, setChooser] = useState({});

  return (
    <>
      {showModalSensor && (
        <ModalSensor
          data={chooser}
          handler_modal={setShowModalSensor}
          show_modal={showModalSensor}
          link={chooser.button == "link" ? true : false}
          handler_reload={handler_reload}
          reload={reload}
          patient_id={chooser.patient_id}
          room={chooser.room}
          subroom={chooser.subroom}
          date_birth={chooser.date_birth}
          age={chooser.age}
          patient_name={chooser.name}
          beacon_name={chooser?.beacon_name}
          beacon_id={chooser?.beacon_id}
          unit_id={chooser.unit_id}
        />
      )}

      <Row className="justify-content-start align-items-center">
        {data.map((item, index) => {
          return (
            <Col xs={12} md={4} lg={3} className="my-2">
              <div
                className="mdd-card-sensor"
                onClick={() => {
                  setChooser(item);
                  setShowModalSensor(true);
                }}
              >
                <div
                  style={{
                    paddingLeft: 15,
                    paddingRight: 15,
                    paddingTop: 15,
                  }}
                >
                  <div className="d-flex justify-content-between mb-2">
                    <p className="card-name card-name-mdd">{item?.initials}</p>
                  </div>

                  <div className="d-flex justify-content-between">
                    <div className="card-quarto card-quarto-mdd mb-3">
                      {item.room == null && item.subroom}
                      {item.room != null && item.room + " | " + item.subroom}
                    </div>
                  </div>
                </div>

                {item.button == "link" ? (
                  <button className="btn-vincular">
                    <p>Vincular sensor</p>
                  </button>
                ) : (
                  <button
                    className="btn-vincular"
                    style={{ backgroundColor: Colors.dark_gray }}
                  >
                    <p>Desvincular sensor</p>
                  </button>
                )}
              </div>
            </Col>
          );
        })}
      </Row>
    </>
  );
}

function Mudancas(props) {
  const { handler_reload, reload, data } = props;
  // Modal para mudança de decúbito
  const [modal, setModal] = useState(false);
  const [dados, setDados] = useState(null);

  return (
    <>
      {dados != null && (
        <ModalDecubito
          data={dados}
          handler_show_modal={setModal}
          show={modal}
          handler_reload={handler_reload}
          reload={reload}
        />
      )}
      <Row className="justify-content-start align-items-center">
        {data.map((item, index) => {
          return (
            <Col key={index} xs={12} md={4} lg={3} className="my-2">
              <div
                className="main-card"
                style={{ cursor: "pointer" }}
                onClick={() => {
                  if (item.tag == "realized") return false;
                  setDados(item);
                  setModal(true);
                }}
              >
                <div
                  className={`status-tag ${item.tag}`}
                  style={{
                    backgroundColor:
                      item.tag == "delayed"
                        ? Colors.brand_red
                        : item.tag == "realized"
                        ? Colors.brand_green
                        : Colors.brand_yellow,
                  }}
                >
                  {item.tag == "delayed"
                    ? "ATRASADA"
                    : item.tag == "realized"
                    ? "REALIZADA"
                    : "EM BREVE"}
                </div>

                <div className="card-name mt-3 mb-1">{item.initials}</div>

                <div className="card-quarto mb-3">
                  {item.room == null && item.subroom}
                  {item.room != null && item.room + " | " + item.subroom}
                </div>

                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                  }}
                >
                  <div className="card-data-sm">
                    <Icon
                      color={Colors.brand_blue}
                      size={20}
                      icon="clock"
                      className="mr-2"
                    />
                    {moment(item.datetime_schedule).format("LT")}
                  </div>
                  <div className="ml-4 card-data-sm">
                    <Icon
                      color={Colors.brand_blue}
                      size={20}
                      icon="refresh"
                      className="mr-2"
                    />
                    {item.position}
                  </div>
                </div>
              </div>
            </Col>
          );
        })}
      </Row>
    </>
  );
}

function Notificacoes(props) {
  function DisableBtn(button, name) {
    var disable = true;
    if (button && name != props.user.dados.name) disable = false;
    return disable;
  }

  const notifications = props.notifications;

  return (
    <Row className="justify-content-start align-items-center">
      {notifications.map((p, i) => {
        return (
          <Col
            key={i}
            xs={12}
            md={6}
            lg={4}
            xl={3}
            className="my-2"
            onClick={() => {
              const state = {};
              state[props.woundState ? "itemCard" : "itemCardConfirmed"] = p;
              props.history.push({
                pathname: "/Home/Notificacoes",
                state,
              });
            }}
          >
            <div className="main-card main-card-notif">
              <div className="card-name card-name-mdd mb-1">
                {p.patient_initials}
              </div>

              <div className="card-quarto card-quarto-mdd">
                {" "}
                {p.room == null && p.subroom}
                {p.room != null &&
                  p.subroom != null &&
                  `${p.room}  |  ${p.subroom}`}
              </div>

              <div
                className="card-data-sm mt-3"
                style={{ display: "flex", flexDirection: "row" }}
              >
                <Icon
                  color={Colors.brand_blue}
                  size={20}
                  icon="band-aid"
                  className="mr-2"
                />
                {p.wounds.length}{" "}
                {p.wounds.length == 1
                  ? "lesão notificada"
                  : "lesões notificadas"}
              </div>

              <div
                className="card-data-sm mt-2"
                style={{ display: "flex", flexDirection: "row" }}
              >
                <Icon
                  color={Colors.brand_blue}
                  size={20}
                  icon={p.type === "admission" ? "enter" : "hospital"}
                  className="mr-2"
                />

                {p.type == "admission" ? "Admissão" : "Incidida"}
              </div>

              <div
                className="mt-3"
                style={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "flex-start",
                  alignItems: "center",
                }}
              >
                <img
                  alt="Foto usuário"
                  className="card-photo"
                  src={
                    p.user_image != null
                      ? p.user_image
                      : require("../../../assets/img/icon_user.png")
                  }
                />

                <div style={{ display: "flex", flexDirection: "column" }}>
                  <div className="card-data-xs" style={{ color: "#000" }}>
                    Por {p.user_name}
                  </div>
                  <div className="card-data-xs">
                    {moment(p.date_verify).format("LLL")}
                  </div>
                </div>
              </div>
              {!props.woundState && (
                <div
                  style={{ boxShadow: "none", border: "none" }}
                  className="card-bottom-confirmed-home d-flex align-items-start"
                >
                  <Icon
                    color={Colors.brand_green}
                    size={18}
                    icon={"check"}
                    className="mr-2"
                  />
                  <div>
                    <p className="mb-0">Confirmada por {p.nurse_name} </p>
                  </div>
                </div>
              )}
            </div>
            {(props.woundState || !p.nurse_name) && (
              <button
                className="mb-0 modal-footer-btn btn-confirm no-radius"
                style={{
                  backgroundColor:
                    DisableBtn(p.button_avaliation, p.user_name) == false
                      ? Colors.brand_pink
                      : Colors.lighter_gray,
                }}
              >
                <p
                  style={{
                    color:
                      DisableBtn(p.button_avaliation, p.user_name) == false
                        ? "white"
                        : Colors.dark_gray,
                  }}
                >
                  Confirmar
                </p>
              </button>
            )}
          </Col>
        );
      })}
    </Row>
  );
}

function Destaques(props) {
  var ranking = props.ranking;

  return (
    <div
      className="destaques-card"
      style={{ display: "flex", flexDirection: "row" }}
    >
      {ranking[0].void != true && (
        <div className="col-destaques mr-5 position-relative">
          <img
            className="medium-photo mb-2 mt-2"
            src={
              ranking[0].image != null
                ? ranking[0].image
                : require("../../../assets/img/icon_user.png")
            }
          />
          <div className="card-name mt-3">{ranking[0].name.split(" ")[0]}</div>
          <div className="card-score">{ranking[0].counter} pontos</div>
          <div
            style={{
              position: "absolute",
              right: 0,
            }}
          >
            <img style={{ width: 28, height: 28 }} src={iconSecond} />
          </div>
        </div>
      )}

      {ranking[1].void != true && (
        <div className="col-destaques position-relative mr-5">
          <img
            className="large-photo"
            src={
              ranking[1].image != null
                ? ranking[1].image
                : require("../../../assets/img/icon_user.png")
            }
          />
          <div className="card-name mt-3">{ranking[1].name.split(" ")[0]}</div>
          <div className="card-score">{ranking[1].counter} pontos</div>
          <div
            style={{
              position: "absolute",
              right: 0,
            }}
          >
            <img style={{ width: 32, height: 32 }} src={iconFisrt} />
          </div>
        </div>
      )}

      {ranking[2].void != true && (
        <div className="col-destaques position-relative">
          <img
            className="medium-photo mb-2 mt-2"
            src={
              ranking[2].image != null
                ? ranking[2].image
                : require("../../../assets/img/icon_user.png")
            }
          />
          <div className="card-name mt-3">{ranking[2].name.split(" ")[0]}</div>
          <div className="card-score">{ranking[2].counter} pontos</div>
          <div
            style={{
              position: "absolute",
              right: 0,
            }}
          >
            <img style={{ width: 30, height: 30 }} src={iconThird} />
          </div>
        </div>
      )}
    </div>
  );
}

function Conquistas(props) {
  return (
    <Row className="pt-1">
      {props.achievements != null &&
        props.achievements.map((item, i) => {
          return (
            <div
              key={i}
              className="mb-2 mr-3 d-flex flex-column align-items-center justify-content-start"
              style={{ width: 80 }}
            >
              <div
                style={{
                  background: HexToRgbA(item.level_color),
                  padding: 10,
                  borderRadius: "100%",
                  width: 75,
                  height: 75,
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  borderWidth: 2,
                  borderStyle: "solid",
                  borderColor: item.level_color,
                }}
              >
                <IconAchievements
                  color={item.level_color}
                  size={44}
                  icon={item.image}
                />
              </div>
              <div className="achievment-name text-center mt-2">
                {item.name_insignia}
              </div>
            </div>
          );
        })}
    </Row>
  );
}

var listDecubito2 = [];
var listVincu2 = [];
function Home({ history }) {
  const dispatch = useDispatch();
  const user = useSelector((state) => state.user);
  const unit = useSelector((state) => state.unit);
  const hospitalPlan = useSelector((state) => state.hospitalPlan);
  const woundState = useSelector((state) => state.defaultWoundState);
  const [showAddPatientModal, setShowAddPatientModal] = useState(false);

  const handleAddPatientRedirect = () => {
    setShowAddPatientModal(true);
    history.push("/Pacientes", { showAddPatientModal: true });
  };

  const [typeClicked, setTypeClicked] = useState(false);

  const [reload, setReload] = useState(false);

  // Modais
  const [showChecklist, setShowChecklist] = useState(false);

  // Flags
  const [relatorioTab, setRelatorioTab] = useState("Hoje");

  const [overview, setOverview] = useState({
    loaded: false,
    patientsWithRiskAndPressureWounds: [],
    patientsWithIncidencePressureWounds: [],
    patientsWithPressureWounds: [],
    patientsWithRisk: [],
    generalPatients: [],
    pressureWounds: [],
    pressureWoundsIncidence: [],
    pressureWoundsAdmission: [],
  });
  const [ranking, setRanking] = useState([]);
  const [achievements, setAchievements] = useState([]);
  const [notifications, setNotifications] = useState([]);
  const [listDecubito, setListDecubito] = useState([]);
  const [listVincu, setListVincu] = useState([]);
  const [loading, setLoading] = useState(true);
  const [loadingOverview, setLoadingOverview] = useState(true);

  useEffect(() => {
    function WebSocketInit() {
      //Web Socket Adonis
      const ws = Ws(`${process.env.REACT_APP_WEB_SOCKET_ENDPOINT}`, {
        path: "ws",
        reconnection: false,
      });
      ws.connect();

      const topic = ws.subscribe("websocket");
      topic.on("ready", () => {
        topic.on("notificationwound", (response) => {
          console.log("[ws] New notification");
          if (response.data.unit_id == unit.selecionado.id) {
            LoadNotifications();
            LoadRanking();
            //LoadAchievements();
            LoadOverview();
          }
        });

        topic.on("confirmedwound", (response) => {
          console.log("[ws] New confirmed");
          if (response.data.unit_id == unit.selecionado.id) {
            LoadNotifications();
            LoadRanking();
            //LoadAchievements();
            LoadOverview();
          }
        });

        // topic.on("notification", (response) => {
        //   console.log("[ws] New decubito");
        //   var obj = response.data;
        //   console.log(obj);

        //   if (obj.tag == "forget") {
        //     //Remove card
        //     var newList = listDecubito2.filter(
        //       (item) => item?.patient_id != parseInt(obj?.patient_id)
        //     );
        //     console.log("newList filter");
        //     console.log(newList);
        //     setListDecubito(newList);
        //     listDecubito2 = newList;

        //     //Remove card Vinculacao
        //     var newListVincu = listVincu2.filter(
        //       (item) => item?.patient_id != parseInt(obj?.patient_id)
        //     );
        //     setListVincu(newListVincu);
        //     listVincu2 = newListVincu;
        //     return false;
        //   }

        //   if (
        //     obj?.unit_id != unit.selecionado?.id ||
        //     obj?.unit_id == undefined ||
        //     obj?.beacon?.id == undefined ||
        //     obj?.patient_id == undefined
        //   )
        //     return false;

        //   var found = listDecubito2.find(
        //     (item) => item.patient_id == obj.patient_id
        //   );
        //   console.log("found");
        //   console.log(found);
        //   if (found != undefined) {
        //     //Se já tiver na lista, atualiza o obj inteiro
        //     var newList = listDecubito2.map((item) => {
        //       if (item.patient_id == obj.patient_id) {
        //         item.age = obj.age;
        //         item.alert = obj.alert;
        //         item.beacon = obj.beacon;
        //         item.color = obj.color;
        //         item.date_birth = obj.date_birth;
        //         item.datetime_schedule = obj.datetime_schedule;
        //         item.datetime_suspend = obj.datetime_suspend;
        //         item.next_datetime_position = obj.next_datetime_position;
        //         item.initials = obj.initials;
        //         item.link_beacon = obj.link_beacon;
        //         item.name = obj.name;
        //         item.notification_id = obj.notification_id;
        //         item.patient_id = obj.patient_id;
        //         item.patient_staus = obj.patient_status;
        //         item.position = obj.position;
        //         item.position_id = obj.position_id;
        //         item.room = obj.room;
        //         item.server = obj.server;
        //         item.sound = obj.sound;
        //         item.subroom = obj.subroom;
        //         item.tag = obj.tag;
        //         item.time_regressive = obj.time_regressive;
        //         item.unit_id = obj.unit_id;
        //         item.unlink_beacon = obj.unlink_beacon;
        //       }
        //       return item;
        //     });
        //     console.log("newList");
        //     console.log(newList);
        //     setListDecubito(newList);
        //     listDecubito2 = newList;
        //   } else {
        //     //É uma nova notificacao, add na lista
        //     setListDecubito([obj, ...listDecubito2]);
        //     listDecubito2.push(obj);
        //   }

        //   //Vinculacao e desvinculacao de sensores
        //   var foundVincu = listVincu2.find(
        //     (item) => item?.patient_id == obj?.patient_id
        //   );

        //   if (foundVincu != undefined) {
        //     var newList = listVincu2.map((item) => {
        //       if (item.patient_id == obj.patient_id) {
        //         item.age = obj.age;
        //         item.beacon_id = obj?.beacon?.id;
        //         item.beacon_name = obj?.beacon?.name;
        //         item.button = obj.link_beacon ? "link" : "unlink";
        //         item.date_birth = obj.date_birth;
        //         item.initials = obj.initials;
        //         item.name = obj.name;
        //         item.patient_id = obj.patient_id;
        //         item.room = obj.room;
        //         item.subroom = obj.subroom;
        //       }
        //       if (obj.link_beacon || obj.unlink_beacon) return item;
        //     });
        //     setListVincu(newList);
        //     listVincu2 = newList;
        //   }
        // });
      });

      ws.on("open", () => {
        console.log("ws connect");
      });

      ws.on("close", () => {
        console.log("ws disconnect");
        setTimeout(() => {
          WebSocketInit();
        }, 9000);
      });
    }

    if (
      unit.selecionado?.id != undefined &&
      unit.selecionado?.url_friendly != "all-units"
    ) {
      WebSocketInit();
    }
  }, [unit]);

  console.log("listDecubito HEREEEE");
  console.log(listDecubito);

  useEffect(() => {
    if (
      unit.selecionado?.id != undefined &&
      unit.selecionado?.url_friendly != "all-units"
    ) {
      LoadRanking();
      //LoadAchievements();
      LoadNotifications();
    }
  }, [unit]);

  useEffect(() => {
    if (unit.selecionado.id) {
      LoadOverview();
    }
  }, [relatorioTab, unit]);

  useEffect(() => {
    if (
      unit.selecionado?.id != undefined &&
      unit.selecionado?.url_friendly != "all-units" &&
      hospitalIsFullType(hospitalPlan)
    ) {
      LoadDecubito();
      LoadVincu();
    } else {
      setLoading(false);
    }
  }, [reload, unit]);

  function LoadOverview() {
    setLoadingOverview(true);
    let params = { unitId: unit.selecionado.id };
    if (relatorioTab == "Hoje") {
      params.today = relatorioTab == "Hoje" ? true : false;
    } else {
      const currentMonth = moment();
      params.month = currentMonth.format("MM");
      params.year = currentMonth.format("YYYY");
    }
    api
      .get(`/dashboard/indicators`, { params })
      .then((response) => {
        if (response.status == 200) {
          console.log("LoadOverview success");
          console.log(response.data);

          setOverview({
            loaded: true,
            ...response.data,
          });
        }
      })
      .catch((error) => {
        console.log("LoadOverview error " + error);
      })
      .finally(() => {
        setLoadingOverview(false);
      });
  }

  function LoadDecubito() {
    api
      .get(`/notifications/beacons/mdd`, {
        params: {
          unit_id: unit.selecionado.id,
          is_home: true,
          //limit: 4,
        },
      })
      .then((response) => {
        if (response.status == 200) {
          let formatedNotifications = orderSensor(response.data, "home");
          console.log(formatedNotifications, 12333);
          console.log("LoadDecubito success");
          console.log(response.data);
          setListDecubito(formatedNotifications);
          listDecubito2 = formatedNotifications;
        }
      })
      .catch((error) => {
        console.log("LoadDecubito error " + error);
      })
      .finally(() => {
        setTimeout(() => {
          setReload(!reload);
        }, 20000);
        setLoading(false);
      });
  }

  function LoadVincu() {
    api
      .get(`/beacons/patients`, {
        params: {
          unit_id: unit.selecionado.id,
          limit: true,
        },
      })
      .then((response) => {
        if (response.status == 200) {
          console.log("LoadVincu success");
          console.log(response.data);
          setListVincu(response.data);
          listVincu2 = response.data;
        }
      })
      .catch((error) => {
        console.log("LoadVincu error " + error);
      });
  }

  function LoadRanking() {
    var date = new Date();
    var month = date.getMonth() + 1;
    if (month < 10) {
      month = `0${month}`;
    }

    api
      .get("/ranking/general", {
        params: {
          month,
          unit_id: unit.selecionado.id,
        },
      })
      .then((response) => {
        if (response.status == 200) {
          console.log("ranking");
          console.log(response.data);

          var obj = { void: true };
          var array = [];
          if (response.data.general[1]?.name != undefined)
            array.push(response.data.general[1]);
          else array.push(obj);

          if (response.data.general[0]?.name != undefined)
            array.push(response.data.general[0]);
          else array.push(obj);

          if (response.data.general[2]?.name != undefined)
            array.push(response.data.general[2]);
          else array.push(obj);

          setRanking(array);
        }
      })
      .catch((error) => {
        console.log("LoadRanking error " + error);
      });
  }

  function VerifyRanking() {
    var verify = false;
    var found = ranking.find((item) => item.void == undefined);
    if (found != undefined) verify = true;
    return verify;
  }

  function SetarOverview(value) {
    overview.loaded = false;
    setOverview(overview);
    setRelatorioTab(value);
  }

  function LoadAchievements() {
    api
      .get("/ranking/users/achievements")
      .then((response) => {
        if (response.status == 200) {
          console.log("achievements");
          console.log(response.data);

          setAchievements(response.data);
        }
      })
      .catch((error) => {
        console.log("LoadAchievements error " + error);
      });
  }

  function LoadNotifications() {
    api
      .get(`/patients-wounds/list`, {
        params: {
          unit_id: unit.selecionado.id,
          type: woundState.selected ? "pending" : "confirmed",
        },
      })
      .then((response) => {
        if (response.status == 200) {
          var array = [];

          response.data.map((item, index) => {
            item.id = index;
            array.push(item);
          });

          console.log("LoadNotifications");
          console.log(array);
          setNotifications(array);
        }
      })
      .catch((error) => {
        console.log("LoadNotifications error" + error);
      });
  }

  function renderChecklist() {
    return <ModalChecklist show={showChecklist} handler={setShowChecklist} />;
  }

  return (
    <div>
      <MainNavbar history={history} />
      {showChecklist && renderChecklist()}

      <Container
        fluid
        className="bg-white"
        // style={{ paddingRight: 80, paddingLeft: 80 }}
      >
        <Row>
          {unit.selecionado?.id != undefined && (
            <>
              <Col xs={12} md={6} lg={4} className="mb-4 mt-5">
                <b className="header">Acessos rápidos</b>
                <div
                  onClick={handleAddPatientRedirect}
                  className="main-card mt-4 mb-4"
                >
                  <Icon
                    color={Colors.brand_blue}
                    size={24}
                    icon="user-plus"
                    className="mb-3"
                  />
                  <br />
                  <b>Adicionar paciente</b>
                </div>
                <div
                  onClick={() => history.push("/Home/NotificarLesoes")}
                  className="main-card"
                >
                  <Icon
                    color={Colors.brand_blue}
                    size={24}
                    icon="warning"
                    className="mb-3"
                  />
                  <br />
                  <b>Notificar lesões</b>
                </div>
              </Col>

              <Col xs={12} md={6} lg={8} className="mb-4 mt-5">
                <Row className="w-100 mb-4 mx-0">
                  <Col className="text-left pl-0" xs={6}>
                    <b className="header">Relatórios</b>
                  </Col>
                  <Col
                    xs={6}
                    className="text-right pr-0"
                    style={{ cursor: "pointer" }}
                    onClick={() => history.push({ pathname: "/Relatorios" })}
                  >
                    <b className="ver-mais">Ver mais</b>
                  </Col>
                </Row>

                <div className="main-card pt-0 px-0 mx-0 pb-2">
                  <Row className="tabs mb-0 px-0 mx-0">
                    <Col
                      xs={6}
                      md={6}
                      lg={6}
                      className="px-0 tab-text-col"
                      onClick={() => SetarOverview("Hoje")}
                    >
                      <h6
                        className={
                          relatorioTab == "Hoje" ? "active-text mb-0" : "mb-0"
                        }
                      >
                        Pacientes internados
                      </h6>
                    </Col>

                    <Col
                      xs={6}
                      md={6}
                      lg={6}
                      className="px-0 tab-text-col"
                      onClick={() => SetarOverview("Mes")}
                    >
                      <h6
                        className={
                          relatorioTab === "Mes" ? "active-text mb-0" : "mb-0"
                        }
                      >
                        Mês
                      </h6>
                    </Col>
                  </Row>

                  <Row className="tab-bar mx-0 w-100 mb-2">
                    <Col
                      className={relatorioTab == "Hoje" ? "active-tab" : ""}
                    />
                    <Col
                      className={relatorioTab == "Mes" ? "active-tab" : ""}
                    />
                  </Row>

                  <Relatorio
                    relatorioTab={relatorioTab}
                    overview={overview}
                    history={history}
                    handler_type_clicked={setTypeClicked}
                  />
                </div>
              </Col>
            </>
          )}
        </Row>

        {hospitalPlan.dados.type === 1 && (
          <Row className="mt-5">
            <Col>
              <Row
                className="w-100 mb-3 mx-0"
                style={{
                  justifyContent: "space-between",
                  alignItems: "center",
                }}
              >
                <Col
                  style={{ cursor: "pointer" }}
                  className="text-left pl-0"
                  onClick={() => history.push({ pathname: "/MapaDecubito" })}
                >
                  <b className="header">Mapa de decúbito</b>
                </Col>
                <Col
                  className="text-right pr-0"
                  style={{ cursor: "pointer" }}
                  onClick={() => history.push({ pathname: "/MapaDecubito" })}
                >
                  <b className="ver-mais">Ver mais</b>
                </Col>
              </Row>

              {!loading && listDecubito.length == 0 && (
                <div className="container-error-default">
                  <p data-testid="decubit-empty-state">
                    Ainda não foram cadastrados pacientes de risco.
                  </p>
                </div>
              )}

              {loading && (
                <div className="text-center">
                  <Spinner animation="border" variant="primary" />
                </div>
              )}

              {!loading && listDecubito.length > 0 && (
                <MudancasNew
                  handler_loading={setLoading}
                  data={listDecubito}
                  handler_reload={setReload}
                  reload={reload}
                  history={history}
                />
              )}
            </Col>
          </Row>
        )}

        {hospitalPlan.dados.type === 1 && (
          <Row className="mt-5">
            <Col>
              <Row
                className="w-100 mb-3 mx-0"
                style={{
                  justifyContent: "space-between",
                  alignItems: "center",
                }}
              >
                <Col
                  style={{ cursor: "pointer" }}
                  className="text-left pl-0"
                  onClick={() => history.push({ pathname: "/Home/Vinculacao" })}
                >
                  <b className="header">
                    Vinculação e desvinculação de sensores
                  </b>
                </Col>
                <Col
                  className="text-right pr-0"
                  style={{ cursor: "pointer" }}
                  onClick={() => history.push({ pathname: "/Home/Vinculacao" })}
                >
                  <b className="ver-mais">Ver mais</b>
                </Col>
              </Row>

              {listVincu.length == 0 && (
                <div className="container-error-default">
                  <p>Ainda não foram cadastrados pacientes.</p>
                </div>
              )}

              <Vinculacao
                data={listVincu}
                handler_reload={setReload}
                reload={reload}
                handler_loading={setLoading}
              />
            </Col>
          </Row>
        )}

        {/* <Row className="mt-5">
          <Col>
            <Row
              className="w-100 mb-3 mx-0"
              style={{ justifyContent: "space-between", alignItems: "center" }}
            >
              <Col
                style={{ cursor: "pointer" }}
                className="text-left pl-0"
                onClick={() =>
                  history.push({ pathname: "/Home/MudancaDecubito" })
                }
              >
                <b className="header">Mudança de decúbito</b>
              </Col>
              <Col
                className="text-right pr-0"
                style={{ cursor: "pointer" }}
                onClick={() =>
                  history.push({ pathname: "/Home/MudancaDecubito" })
                }
              >
                <b className="ver-mais">Ver mais</b>
              </Col>
            </Row>

            {listDecubito.length == 0 && (
              <div className="container-error-default">
                <p>Ainda não foram cadastradas mudança de decúbito.</p>
              </div>
            )}

            <Mudancas
              data={listDecubito}
              handler_reload={setReload}
              reload={reload}
            />
          </Col>
        </Row> */}

        <Row className="my-5">
          <Col>
            <Row
              className="w-100 mb-3 mx-0"
              style={{ justifyContent: "space-between", alignItems: "center" }}
            >
              <Col
                className="text-left pl-0"
                style={{ cursor: "pointer" }}
                onClick={() => history.push({ pathname: "/Home/Notificacoes" })}
              >
                <b className="header">Últimas notificações</b>
              </Col>
              <Col
                className="text-right pr-0"
                style={{ cursor: "pointer" }}
                onClick={() => history.push({ pathname: "/Home/Notificacoes" })}
              >
                <b className="ver-mais">Ver todas</b>
              </Col>
            </Row>

            {notifications.length == 0 && (
              <div className="container-error-default">
                <p>
                  Ainda não foram realizadas notificações de lesão por pressão.
                </p>
              </div>
            )}

            {notifications.length > 0 && (
              <Notificacoes
                notifications={notifications}
                user={user}
                history={history}
                woundState={woundState.selected}
              />
            )}
          </Col>
        </Row>

        {/* <Row className="my-5">
          <Col className="mr-2">
            <Row
              className="w-100 mb-3 mx-0"
              style={{ justifyContent: "space-between", alignItems: "center" }}
            >
              <Col
                className="text-left pl-0"
                style={{ cursor: "pointer" }}
                onClick={() => history.push({ pathname: "/Home/Ranking" })}
              >
                <b className="header">Destaques do mês</b>
              </Col>
              <Col
                className="text-right pr-0"
                style={{ cursor: "pointer" }}
                onClick={() => history.push({ pathname: "/Home/Ranking" })}
              >
                <b className="ver-mais">Ver mais</b>
              </Col>
            </Row>

            {!VerifyRanking() && (
              <div className="container-error-default">
                <p>
                  Os profissionais mais engajados serão reconhecidos e exibidos
                  aqui.
                </p>
              </div>
            )}

            {VerifyRanking() && <Destaques ranking={ranking} />}
          </Col>

          <Col>
            <Row
              className="w-100 mb-3 mx-0"
              style={{ justifyContent: "space-between", alignItems: "center" }}
            >
              <Col
                className="text-left pl-0"
                style={{ cursor: "pointer" }}
                onClick={() => history.push({ pathname: "/Home/Conquistas" })}
              >
                <b className="header">Minhas conquistas</b>
              </Col>
              <Col
                className="text-right pr-0"
                style={{ cursor: "pointer" }}
                onClick={() => history.push({ pathname: "/Home/Conquistas" })}
              >
                <b className="ver-mais">Ver todas</b>
              </Col>
            </Row>

            {achievements.length == 0 && (
              <div className="container-error-default">
                <p>
                  Seu engajamento será recompensado, as conquistas serão
                  exibidas aqui.
                </p>
              </div>
            )}

            {achievements.length > 0 && (
              <Conquistas achievements={achievements} />
            )}
          </Col>
        </Row> */}
      </Container>
    </div>
  );
}

export default Home;
