import moment from "moment";
import React, { useEffect, useState } from "react";
import { Col, Row, Modal, Spinner, Form } from "react-bootstrap";
import { useDispatch } from "react-redux";

import api from "@Services/api";
import Colors from "@Styles/Colors";
import Icon from "@Functions/Icon";
import { Show_Alert } from "@Store/actions/alert";

import "../../../Configuracoes/Tabs/PainelSensores/PainelSensores.scss";
import ReactInputMask from "react-input-mask";
import ReactSelect from "react-select";

const weekDays = [
  "Segunda",
  "Terça",
  "Quarta",
  "Quinta",
  "Sexta",
  "Sábado",
  "Domingo",
];

function ModalEditShift({ ...props }) {
  const dispatch = useDispatch();
  const { handler_show_modal, show_modal, handler_reload, reload, shift } =
    props;

  const [name, setName] = useState(shift.name);
  const [startTime, setStartTime] = useState(shift.start_time);
  const [startDateTime, setStartDateTime] = useState(
    moment(shift.start_date).format("DD/MM/YYYY")
  );
  const [workTime, setWorkTime] = useState(shift.work_time);
  const [offTime, setOffTime] = useState(shift.off_time);
  const [status, setStatus] = useState(shift.status);
  const [shiftWeekDays, setShiftWeekDays] = useState(
    shift.week_days == "null"
      ? []
      : typeof shift.week_days === "string"
      ? JSON.parse(shift.week_days)
      : JSON.parse(JSON.stringify(shift.week_days))
  );
  const [unit, setUnit] = useState({
    value: shift.unit.id,
    label: shift.unit.name,
  });
  const [loadingBtn, setLoadingBtn] = useState("none");

  function Save() {
    setLoadingBtn("flex");
    api
      .put(`hospitals/1/shifts/${shift.id}`, {
        name,
        status,
        start_time: startTime,
        start_date: moment(startDateTime, "DD/MM/YYYY").format("YYYY-MM-DD"),
        work_time: workTime,
        off_time: offTime,
        week_days: shiftWeekDays.filter((item) => weekDays.includes(item)),
        unit_id: unit.value,
      })
      .then((res) => {
        dispatch(
          Show_Alert({
            type: "success",
            msg: "Plantão editado com sucesso",
          })
        );
        handler_reload(!reload);
      })
      .catch((error) => {
        console.log("Save error " + error);
        console.log(error.response.status);
        dispatch(
          Show_Alert({
            type: "error",
            msg: "Não foi possível salvar o plantão",
          })
        );
      })
      .finally(() => {
        handler_show_modal(false);
        setLoadingBtn("none");
      });
  }

  function VerifyFields() {
    let verify = false;

    if (startTime.length < 5) {
      verify = false;
    } else if (!startTime.match(/^(?:[01]\d|2[0-3]):[0-5]\d(?::[0-5]\d)?$/g)) {
      verify = false;
    } else if (
      !startDateTime.match(
        /^(0[1-9]|[12][0-9]|3[01])\/(0[1-9]|1[012])\/(2\d{3}|[3-9]\d{3})$/g
      )
    ) {
      verify = false;
    } else if (!workTime.match(/^(?:[01]\d|2[0-3]):[0-5]\d(?::[0-5]\d)?$/g)) {
      verify = false;
    } else if (name.length === 0) {
      verify = false;
    } else {
      verify = true;
    }

    return verify;
  }

  return (
    <Modal
      show={show_modal}
      onHide={() => handler_show_modal(false)}
      size="md"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      className="modal-filter"
      data-testid="beacon-panel-toggle"
    >
      <Modal.Body className="p-0">
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            padding: 20,
            paddingBottom: 6,
          }}
        >
          <div>
            <div className="modal-title">Editar plantão</div>
          </div>
          <div>
            <div
              className="modal-close-btn"
              onClick={() => handler_show_modal(false)}
            >
              <Icon color={Colors.darkest_gray} size={15} icon="x" />
            </div>
          </div>
        </div>
        <div className="modal-all">
          <div className="header-discharge mt-3">
            <div className="mb-4">
              <Form.Label className="field-label">Nome do plantão</Form.Label>
              <input
                className="default-input"
                type="text"
                placeholder="Digite o nome do plantão"
                value={name}
                onChange={(e) => setName(e.target.value)}
              />
            </div>
            <div className="mb-4">
              <Form.Label className="field-label">Unidade</Form.Label>
              <ReactSelect
                captureMenuScroll={false}
                classNamePrefix="react-select"
                placeholder={"Selecionar unidade"}
                value={unit}
                isClearable={false}
                isDisabled={true}
              />
            </div>
            <Row className="mb-4">
              <Col md={6}>
                <Form.Label className="field-label">
                  Horas trabalhadas
                </Form.Label>
                <div className="default-input">
                  <ReactInputMask
                    style={{ border: "none", width: "80%" }}
                    mask="99:99"
                    maskChar={null}
                    type="text"
                    placeholder="00:00"
                    value={workTime}
                    onChange={(e) => setWorkTime(e.target.value)}
                  />
                </div>
              </Col>
              <Col md={6}>
                <Form.Label className="field-label">
                  Horas de descanso
                </Form.Label>
                <input
                  value={offTime}
                  className="default-input"
                  type="number"
                  placeholder="Horas de folga"
                  onChange={(e) => setOffTime(e.target.value)}
                />
              </Col>
            </Row>
            <div className="mb-4">
              <Row style={{ marginBottom: 16 }}>
                <Col md={6} sm={12}>
                  <Form.Label className="field-label">
                    Data de início
                  </Form.Label>
                  <div
                    style={{
                      borderColor: "#ddd",
                    }}
                    className="default-input"
                  >
                    <Icon
                      color={Colors.darker_gray}
                      size={24}
                      icon="calendar"
                      className="mr-2"
                    />
                    <ReactInputMask
                      style={{ border: "none", width: "80%" }}
                      mask={"99/99/9999"}
                      maskChar={null}
                      type="text"
                      placeholder="DD/MM/AAAA"
                      value={startDateTime}
                      onChange={(e) => setStartDateTime(e.target.value)}
                    />
                  </div>
                </Col>
                <Col md={6} sm={12}>
                  <Form.Label className="field-label">
                    Horário de início
                  </Form.Label>
                  <div
                    style={{
                      borderColor: "#ddd",
                    }}
                    className="default-input"
                  >
                    <Icon
                      color={Colors.darker_gray}
                      size={24}
                      icon="clock"
                      className="mr-2"
                    />
                    <ReactInputMask
                      style={{ border: "none", width: "80%" }}
                      mask="99:99"
                      maskChar={null}
                      type="text"
                      placeholder="00:00"
                      value={startTime}
                      onChange={(e) => setStartTime(e.target.value)}
                    />
                  </div>
                </Col>
              </Row>
            </div>
            <div className="mb-4">
              <Row style={{ marginBottom: 16 }}>
                <Col sm={12}>
                  <Form.Label className="field-label">
                    Dias da semana do plantão
                  </Form.Label>
                  <div>
                    {weekDays.map((item) => {
                      let weekIsIncluded = shiftWeekDays.includes(item);
                      return (
                        <button
                          style={{
                            background: weekIsIncluded
                              ? Colors.brand_blue
                              : Colors.lightest_gray,
                            width: 100,
                          }}
                          className="btn-click"
                          onClick={() => {
                            if (weekIsIncluded) {
                              setShiftWeekDays(
                                shiftWeekDays.filter((day) => day !== item)
                              );
                            } else {
                              console.log(shiftWeekDays, 111);
                              setShiftWeekDays([...shiftWeekDays, item]);
                            }
                          }}
                        >
                          <p
                            className="mb-0"
                            style={{
                              color: weekIsIncluded
                                ? "white"
                                : Colors.darker_gray,
                            }}
                          >
                            {item}
                          </p>
                        </button>
                      );
                    })}
                  </div>
                </Col>
              </Row>
            </div>
            <div className="mb-4">
              <Form.Label className="field-label">Status</Form.Label>
              <div>
                <Form.Check
                  type="switch"
                  label=" "
                  checked={status}
                  readOnly={true}
                  onClick={() => setStatus(!status)}
                  id={`sensor-status`}
                  className={`d-inline-block z-index-5 o-toggle o-toggle-success ${
                    true ? "is-checked" : ""
                  }`}
                />
                <span className="sensor-status-label">
                  {status ? "Ativo" : "Inativo"}
                </span>
              </div>
            </div>
          </div>
        </div>
      </Modal.Body>

      <Modal.Footer className="row justify-content-end align-items-center pb-0">
        <Col xs={4} md={3} lg={3} className="px-0 mr-3">
          <div
            className="secondary-btn modal-footer-btn"
            onClick={() => handler_show_modal(false)}
          >
            <p>Cancelar</p>
          </div>
        </Col>
        <Col xs={4} md={3} lg={3} className="px-0">
          <button
            disabled={!VerifyFields() || loadingBtn === "flex"}
            style={{
              backgroundColor: VerifyFields()
                ? Colors.brand_pink
                : Colors.lighter_gray,
              cursor: "pointer",
            }}
            className="mb-0 modal-footer-btn btn-confirm"
            onClick={() => Save()}
            data-testid="beacon-panel-toggle-action"
          >
            <p
              className="mb-0"
              style={{
                color: VerifyFields() ? Colors.white : Colors.dark_gray,
                display: loadingBtn == "none" ? "flex" : "none",
              }}
            >
              Salvar
            </p>

            <Spinner
              style={{ display: loadingBtn }}
              animation="border"
              role="status"
              variant="light"
              size="sm"
            />
          </button>
        </Col>
      </Modal.Footer>
    </Modal>
  );
}

export default ModalEditShift;
