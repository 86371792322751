import React, { useState, useEffect, useRef } from "react";
import {
  Col,
  Container,
  Row,
  Form,
  Table,
  Dropdown,
  Spinner,
} from "react-bootstrap";
import CardPaciente from "./CardPaciente";
import ModalAlta from "./ModalAlta";

import Icon from "../../functions/Icon";
import { GetColorAvaliation } from "../../functions/utils";
import Display from "../../components/Display";
import FilterChooser from "../../components/Modals/FilterChooser";
import SortDropdown from "../../components/Dropdowns/SortDropdown";
import DischargePatient from "../../components/Modals/DischargePatient";

import Colors from "../../styles/Colors";
import "../../styles/global.css";
import "./styles.css";

import { useDispatch, useSelector } from "react-redux";
import api from "../../../src/services/api";
import moment from "moment";
import { Show_Alert } from "../../store/actions/alert";
import VerifyPw from "../../components/Modals/VerifyPw";

function CardView({ history, ...props }) {
  return (
    <Row className="w-100 mt-4">
      {props.data.length > 0 &&
        props.data.map((p, i) => {
          return (
            <Col key={i} xs={12} md={6} lg={4} className={"px-2 mb-4"}>
              <CardPaciente
                status={props.status}
                dados={p}
                listPatient={props.data}
                handler_patient={props.handler_patient}
                history={history}
                handler_reload={props.handler_reload}
                reload={props.reload}
                handler_reload_beacons={props.handler_reload_beacons}
              />
            </Col>
          );
        })}
    </Row>
  );
}

function ListView({ history, ...props }) {
  const { sort, handler_sort, handler_reload_beacons } = props;
  const [patient, setPatient] = useState([]);
  const [userChooser, setUserChooser] = useState(0);
  const [showPw, setShowPw] = useState(false);
  const dispatch = useDispatch();

  function ChangeSort(orders) {
    console.log("ORDERS TYPE");
    console.log(sort?.orders_type);
    switch (orders) {
      case "name":
        handler_sort({
          icon: "z-a",
          orders: "name",
          orders_type:
            sort?.orders_type == undefined
              ? "ASC"
              : sort.orders_type == "DESC"
              ? "ASC"
              : "DESC",
          name:
            sort?.orders_type == undefined
              ? "Nome (A - Z)"
              : sort.orders_type == "ASC"
              ? "Nome (Z - A)"
              : "Nome (A - Z)",
        });
        break;
      case "room":
        handler_sort({
          icon: "bed",
          orders: "room",
          orders_type:
            sort?.orders_type == undefined
              ? "ASC"
              : sort.orders_type == "DESC"
              ? "ASC"
              : "DESC",
          name: "Quarto / Leito",
        });
        break;
      case "qtd_wounds":
        handler_sort({
          icon: "band-aid",
          orders: "qtd_wounds",
          orders_type:
            sort?.orders_type == undefined
              ? "ASC"
              : sort.orders_type == "DESC"
              ? "ASC"
              : "DESC",
          name:
            sort?.orders_type == undefined
              ? "Menor quantidade de lesões"
              : sort.orders_type == "ASC"
              ? "Maior quantidade de lesões"
              : "Menor quantidade de lesões",
        });
        break;
      case "score":
        handler_sort({
          icon: "warning",
          orders: "score",
          orders_type:
            sort?.orders_type == undefined
              ? "DESC"
              : sort.orders_type == "DESC"
              ? "ASC"
              : "DESC",
          name:
            sort?.orders_type == undefined
              ? "Grau de risco menor"
              : sort.orders_type == "DESC"
              ? "Grau de risco maior"
              : "Grau de risco menor",
        });
        break;
    }
  }

  return (
    <>
      {showPw && (
        <VerifyPw
          handler_show_pw={setShowPw}
          show_pw={showPw}
          handler_user={setUserChooser}
          id_user={userChooser}
        />
      )}
      <Table responsive hover className="patient-table mt-4">
        {props.show_discharge && (
          <DischargePatient
            patient={patient}
            listPatient={props.listPatient}
            handler_patient={props.handler_patient}
            handler_show_discharge={props.handler_show_discharge}
            show_discharge={props.show_discharge}
            handler_reload={props.handler_reload}
            handler_user={setUserChooser}
            reload={props.reload}
            handler_reload_beacons={handler_reload_beacons}
          />
        )}
        <thead>
          <tr>
            <th>Nº</th>
            <th onClick={() => ChangeSort("name")}>Nome</th>
            <th onClick={() => ChangeSort("room")}>Leito</th>
            <th onClick={() => ChangeSort("qtd_wounds")}>Qntd. de lesões</th>
            {props.status && (
              <th onClick={() => ChangeSort("score")}>Avaliação de risco</th>
            )}
            <th></th>
          </tr>
        </thead>
        <tbody>
          {props.data.length > 0 &&
            props.data.map((p, i) => {
              return (
                <tr
                  key={i}
                  onClick={() => {
                    dispatch({ type: "SET_PATIENT_SELECTED", dados: p });
                    dispatch({ type: "RESET_PATIENT_TAB" });
                    history.push({ pathname: "/Pacientes/Perfil-Paciente" });
                  }}
                >
                  <td>{i + 1}</td>
                  <td>{p.initials}</td>
                  <td>
                    {p.room == null && p.subroom}
                    {p.room != null &&
                      p.subroom != null &&
                      `${p.room}  |  ${p.subroom}`}
                  </td>
                  <td>
                    <div className="d-flex" style={{ marginLeft: 8 }}>
                      {p.qtd_wounds_incidence == 0 &&
                        p.qtd_wounds_admission == 0 && <p>Nenhuma lesão</p>}

                      {p.qtd_wounds_incidence > 0 && (
                        <p>
                          {p.qtd_wounds_incidence}{" "}
                          {p.qtd_wounds_incidence > 1
                            ? "lesões incididas"
                            : "lesão incidida"}
                        </p>
                      )}

                      {p.qtd_wounds_incidence > 0 &&
                        p.qtd_wounds_admission > 0 && <p className="mx-2">|</p>}

                      {p.qtd_wounds_admission > 0 && (
                        <p>
                          {p.qtd_wounds_admission}{" "}
                          {p.qtd_wounds_admission > 1
                            ? "lesões admitidas"
                            : "lesão admitida"}
                        </p>
                      )}
                    </div>
                  </td>
                  {props.status && (
                    <td>
                      <Row className="mt-2 dados">
                        <div
                          style={{
                            width: 18,
                            height: 18,
                            borderRadius: 14,
                            backgroundColor: GetColorAvaliation(p.score),
                            marginLeft: 1,
                            marginRight: 11,
                          }}
                        />
                        {p.score > 0 && (
                          <p style={{ marginRight: 2 }}>Escore {p.score}:</p>
                        )}
                        <p>{p.score_description}</p>
                      </Row>
                    </td>
                  )}
                  <td className="btn-cell">
                    {props.status && (
                      <div
                        className="secondary-btn"
                        style={{ height: 35, width: 106 }}
                        onClick={(e) => {
                          e.stopPropagation();
                          setPatient(p);
                          props.handler_show_discharge(true);
                        }}
                      >
                        <Icon
                          color={Colors.brand_pink}
                          size={20}
                          icon="user-check"
                          className="mr-2"
                        />
                        Dar alta
                      </div>
                    )}
                  </td>
                </tr>
              );
            })}
        </tbody>
      </Table>
    </>
  );
}

function PacientesView({ history, ...props }) {
  let isRequestActive = false;
  const {
    handler_new_patient,
    new_patient,
    filter_overview,
    handler_reload_beacons,
    reload_patients,
  } = props;

  // console.log("filter_overview inside pacientesview");
  // console.log(filter_overview);
  const dispatch = useDispatch();
  const unit = useSelector((state) => state.unit);
  const patientsFilter = useSelector((state) => state.patientsFilter);

  const listScroll = useRef(null);
  const scrollToRefObject = (ref) => window.scrollTo(0, ref.current?.offsetTop);

  const [loading, setLoading] = useState(true);

  // Ativos/Alta
  const [ativos, setAtivos] = useState(props.ativos);
  const [showDischarge, setShowDischarge] = useState(false);

  // Exibição
  const [data, setData] = useState([]);
  const [filter, setFilter] = useState(null);
  const [sort, setSort] = useState({});
  const [display, setDisplay] = useState("card");
  const [search, setSearch] = useState("");

  // Filtros
  const [unidades, setUnidades] = useState([]);
  const [qtdsLesoes, setQtdsLesoes] = useState([]);
  const [escores, setEscores] = useState([]);
  const [type, setType] = useState("");
  const [hasWound, setHasWound] = useState("");

  // Paginação
  const [currentPage, setCurrentPage] = useState(1);
  const [lastPage, setLastPage] = useState(null);
  const [qtdDisplay, setQtdDisplay] = useState(12);
  const [totalResults, setTotalResults] = useState(null);

  const [hoverBack, setHoverBack] = useState(false);
  const [hoverNext, setHoverNext] = useState(false);

  // Modal alta
  const [showAlta, setShowAlta] = useState(false);
  const [pacienteAlta, setPacienteAlta] = useState(null);

  const [date_start, setDate_start] = useState("");
  const [date_end, setDate_end] = useState("");

  const [pagination, setPagination] = useState([]);
  const [reload, setRelaod] = useState(false);

  const [storedFilterLoaded, setStoredFilterLoded] = useState(false);

  useEffect(() => {
    //Esses filtros vem do overview (Hoje/Mes) que tem tanto em home quando em relatorios
    //São filtros diferentes dependendo de qual info foi clicada
    console.log("FILTER_OVERVIEW");
    console.log(filter_overview);
    if (filter_overview != undefined) {
      var obj = filter_overview;
      if (obj.has_wound != null) setHasWound(obj.has_wound);
      if (obj.score != null)
        setEscores(["none", "low", "moderate", "high", "veryhigh"]);
      if (obj.type != null) setType(obj.type);
      if (obj.score) {
        setSort({
          icon: "warning",
          name: "Grau de risco maior",
          orders: "score",
          orders_type: "ASC",
        });
      } else {
        setSort({
          icon: "band-aid",
          name: "Maior quantidade de lesões",
          orders: "qtd_wounds",
          orders_type: "DESC",
        });
      }
    }
  }, [filter_overview]);

  useEffect(() => {
    let anyFormIsChecked = 0;
    if (patientsFilter.dados && patientsFilter.dados.ativos === ativos) {
      if (patientsFilter.dados.unidade.length > 0) {
        setUnidades(patientsFilter.dados.unidade);
        anyFormIsChecked++;
      }
      if (patientsFilter.dados.qtdLesoes.length > 0) {
        setQtdsLesoes(patientsFilter.dados.qtdLesoes);
        anyFormIsChecked++;
      }
      if (patientsFilter.dados.dateStart != "") {
        setDate_start(patientsFilter.dados.dateStart);
        anyFormIsChecked++;
      }
      if (patientsFilter.dados.dateEnd != "") {
        setDate_end(patientsFilter.dados.dateEnd);
        anyFormIsChecked++;
      }
      if (patientsFilter.dados.escore.length > 0) {
        setEscores(patientsFilter.dados.escore);
        anyFormIsChecked++;
      }
    }
    if (anyFormIsChecked === 0 && patientsFilter.dados?.ativos === ativos) {
      isRequestActive = false;
    } else {
      isRequestActive = true;
    }
  }, []);

  useEffect(() => {
    //Atualiza a lista quando um novo paciente é cadastrado
    if (new_patient.id != undefined) {
      setData([new_patient, ...data]);
      handler_new_patient({});
    }
  });

  useEffect(() => {
    function LoadPatients() {
      // console.log("ESCORES ");
      // console.log(escores);
      // console.log("sort");
      // console.log(sort);

      let listUnits = unit.selecionado?.id;
      if (unidades.length > 0) {
        listUnits = unidades.join(",");
      }

      let listEscores = "";
      if (escores.length > 0) {
        listEscores = escores.join(",");
      }

      // console.log("LIST UNITS " + listUnits);
      // console.log("LISTESCORE " + listEscores);
      // console.log("orders " + sort.orders);
      // console.log("orders_type " + sort.orders_type);
      // console.log("qtdsLesoes " + qtdsLesoes.join(","));

      api
        .get(`/patients?page=${currentPage}&pageSize=${qtdDisplay}`, {
          params: {
            unit_id: listUnits,
            date_start,
            date_end,
            status: ativos == true ? 1 : 0,
            orders: sort.orders,
            orders_type: sort.orders_type,
            qtd_wounds: qtdsLesoes.join(","),
            name: search,
            score: listEscores,
            has_wound: hasWound,
            type,
          },
        })
        .then((response) => {
          if (response.status == 200) {
            console.log("LoadPatients");
            console.log(response.data);

            setData(response.data.data);
            setLastPage(response.data.lastPage);
            setTotalResults(response.data.total);
            BuildPagination(response.data.page, response.data.lastPage);
          }
        })
        .catch((error) => {
          console.log("LoadPatients error " + error);
        })
        .finally(() => {
          //Usado pra descer a tela na altura da listagem
          //scrollToRefObject(listScroll);
          setLoading(false);
        });
    }
    if (
      patientsFilter.dados?.ativos === undefined ||
      (patientsFilter.dados?.ativos === ativos && !isRequestActive) ||
      (patientsFilter.dados?.ativos !== ativos && isRequestActive) ||
      (patientsFilter.dados?.ativos == false && !isRequestActive)
    ) {
      LoadPatients();
    }
  }, [
    reload_patients,
    unidades,
    qtdsLesoes,
    sort,
    date_start,
    date_end,
    currentPage,
    qtdDisplay,
    search,
    reload,
    unit,
    escores,
    type,
    hasWound,
  ]);

  useEffect(() => {
    setCurrentPage(1);
    setTotalResults(null);
  }, [unit]);

  function BuildPagination(currentPage, lastPage) {
    var pagination = [];
    for (var i = currentPage; i < currentPage + 5; i++) {
      if (i == currentPage && currentPage > 2) {
        pagination.push(currentPage - 2);
      }
      if (i == currentPage && currentPage > 1) {
        pagination.push(currentPage - 1);
      }

      if (i <= lastPage) {
        pagination.push(i);
      }
    }
    setPagination(pagination);
  }

  function SetarDisplay(qtd) {
    setCurrentPage(1);
    setQtdDisplay(qtd);
  }

  function renderDisplay() {
    var n = 0;

    if (lastPage > 1) {
      n = qtdDisplay * currentPage;
      if (currentPage == lastPage) n = totalResults;
    } else {
      n = totalResults;
    }

    return n;
  }

  return (
    <div>
      {showAlta && pacienteAlta != null && <ModalAlta dados={pacienteAlta} />}

      <Row
        ref={listScroll}
        className="px-2 w-100"
        style={{ justifyContent: "space-between" }}
      >
        <div style={{ display: "flex", flexDirection: "row" }}>
          <FilterChooser
            ativos={ativos}
            cssStyles={{ marginRight: 10 }}
            handler_unidade={setUnidades}
            handler_qtdlesoes={setQtdsLesoes}
            handler_escore={setEscores}
            handler_date_start={setDate_start}
            handler_date_end={setDate_end}
            handler_current_page={setCurrentPage}
            unidades_selected={unidades}
            qtd_lesoes_selected={qtdsLesoes}
            date_start_selected={date_start}
            date_end_selected={date_end}
            escores_selected={escores}
          />
          <SortDropdown
            handler={setSort}
            sort={sort}
            handler_current_page={setCurrentPage}
            status={props.ativos}
          />
        </div>
        <Display value={display} onclick={setDisplay} />
      </Row>
      <Row className="px-2 w-100 mt-4 ">
        <Col xs={12} md={4} lg={4} className="p-0">
          <div className="field-label">Buscar paciente</div>
          <input
            className="default-input"
            placeholder={
              process.env.REACT_APP_HOSPITAL == "HSL"
                ? "Digite as iniciais do nome do paciente"
                : "Digite o nome do paciente"
            }
            onChange={(v) => {
              setCurrentPage(1);
              setSearch(v.target.value);
            }}
          />
        </Col>
      </Row>

      {!loading && data.length == 0 && (
        <div className="container-error-default mt-4">
          <p>Nenhum paciente foi encontrado.</p>
        </div>
      )}

      {loading && (
        <div className="mt-3 d-flex justify-content-center w-100">
          <Spinner animation="border" variant="primary" />
        </div>
      )}

      {display == "card" && (
        <CardView
          handler_patient={setData}
          status={props.ativos}
          data={data}
          history={history}
          handler_reload={setRelaod}
          reload={reload}
          handler_reload_beacons={handler_reload_beacons}
        />
      )}

      {display == "list" && (
        <ListView
          handler_patient={setData}
          status={props.ativos}
          data={data}
          handler_show_discharge={setShowDischarge}
          show_discharge={showDischarge}
          history={history}
          handler_reload={setRelaod}
          reload={reload}
          handler_sort={setSort}
          sort={sort}
          handler_reload_beacons={handler_reload_beacons}
        />
      )}

      <Row
        className="px-2 w-100"
        style={{ justifyContent: "space-between", alignItems: "center" }}
      >
        <Col xs={4} md={4} lg={3} style={{ textAlign: "left" }}>
          <div className="sm-txt" style={{ color: Colors.dark_gray }}>
            Exibindo
          </div>
          <div className="sm-txt">
            {renderDisplay()} de {totalResults} resultados
          </div>
        </Col>

        <Col xs={4} md={4} lg={3} style={{ textAlign: "center" }}>
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "center",
            }}
          >
            {currentPage > 1 && (
              <div
                className="btn-nav-arrows"
                onMouseEnter={() => setHoverBack(true)}
                onMouseLeave={() => setHoverBack(false)}
                onClick={() => setCurrentPage(currentPage - 1)}
              >
                <Icon
                  color={hoverBack ? Colors.brand_blue : Colors.darker_gray}
                  size={22}
                  icon="arrow-left"
                />
              </div>
            )}

            {pagination.map((item) => {
              return (
                <div
                  key={item}
                  className={
                    item == currentPage
                      ? "btn-nav-numbers active"
                      : "btn-nav-numbers"
                  }
                  onClick={() => {
                    setCurrentPage(item);
                  }}
                >
                  {item}
                </div>
              );
            })}

            {currentPage < lastPage && (
              <div
                className="btn-nav-arrows"
                style={{ transform: `rotateY(180deg)` }}
                onMouseEnter={() => setHoverNext(true)}
                onMouseLeave={() => setHoverNext(false)}
                onClick={() => setCurrentPage(currentPage + 1)}
              >
                <Icon
                  color={hoverNext ? Colors.brand_blue : Colors.darker_gray}
                  size={22}
                  icon="arrow-left"
                />
              </div>
            )}
          </div>
        </Col>

        <Col xs={4} md={4} lg={3}>
          <Dropdown style={{ textAlign: "right" }}>
            <Dropdown.Toggle
              variant="secondary"
              className="dropdown-nav-btn"
              id="dropdown-basic"
            >
              {qtdDisplay} resultados &nbsp;&nbsp;
            </Dropdown.Toggle>

            {display == "card" && (
              <Dropdown.Menu>
                <Dropdown.Item onClick={() => SetarDisplay(12)}>
                  12 resultados
                </Dropdown.Item>
                <Dropdown.Item onClick={() => SetarDisplay(24)}>
                  24 resultados
                </Dropdown.Item>
                <Dropdown.Item onClick={() => SetarDisplay(36)}>
                  36 resultados
                </Dropdown.Item>
              </Dropdown.Menu>
            )}

            {display == "list" && (
              <Dropdown.Menu>
                <Dropdown.Item onClick={() => SetarDisplay(12)}>
                  12 resultados
                </Dropdown.Item>
                <Dropdown.Item onClick={() => SetarDisplay(24)}>
                  24 resultados
                </Dropdown.Item>
                <Dropdown.Item onClick={() => SetarDisplay(36)}>
                  36 resultados
                </Dropdown.Item>
              </Dropdown.Menu>
            )}
          </Dropdown>
        </Col>
      </Row>
    </div>
  );
}

export default PacientesView;
